import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Order, Orders} from "../shared/classes/order";
import {environment} from "../../environments/environment";
import {User} from "../shared/classes/user";
import {AppNotifications} from "../shared/classes/mail";

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    urlBase = environment.urlBase;
    apikey = environment.apikey;

    getDatos: any;

    constructor(
        private http: HttpClient,
    ) {
    }

    sendGET_SettingsApp(id) {
        const headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>(this.urlBase + '/SettingsApp/' + id + '/' + this.apikey + '/1/1', {headers});
    }

    sendGET_Splashscreen() {
        const headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>(this.urlBase + '/SettingsApp/SplashScreen/' + this.apikey + '/1/1', {headers});
    }

    getCategories() {
        const headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>(this.urlBase + '/CategoriesApp/1/0', {headers});
    }

    sendGET_Home_List_Product() {
        const headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + this.apikey + '/0/1', {headers});
    }

    sendGET_Home_List_Product_All() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'qs-limit': "100",
        });
        return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + this.apikey, {headers});
    }

    getOneSlider(uid) {
        let headers = new HttpHeaders({
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/Sliders/${uid}`, {headers});
    }

    getCategoryByUrl(url) {
        let headers = new HttpHeaders({
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/Categories/1/${url}`, {headers});
    }

    getProductsByOrder(data) {
        const headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>(this.urlBase +
            '/Products/'
            + this.apikey
            + '/1/'
            + data.total_docs_by_collection
            + '/' + data.num_page
            + '/' + data.limit
            + '/' + data.offset
            + '/' + data.order_by
            + '/' + data.order, {headers});
    }

    getContentsWeb() {
        let headers = new HttpHeaders({
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/ContentsWeb`, {headers});
    }

    getProductsByCategory(data) {
        let headers = new HttpHeaders({
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/Products/${this.apikey}/1/${data.id}/${data.total_docs}/${data.num_page}/${data.limit}/${data.num_docs_page}/product_name/asc`, {headers});
    }

    getSubcategoryByUrl(url) {
        let headers = new HttpHeaders({
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/SubCategories/1/${url}/1/1`, {headers});
    }

    getClasificationsByUrl(url) {
        let headers = new HttpHeaders({
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/Classifications/1/${url}/1/1`, {headers});
    }

    get_products() {
        let headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>('https://store.centralhidraulica.com/migrate-products', {headers});
    }

    sendGET_Category() {
        const headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>(this.urlBase + '/CategoriesApp/1/0', {headers});
    }

    sendGET_subcategory(orderField, orderBy) {
        const headers = new HttpHeaders({
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/SubCategories/${orderField}/${orderBy}`, {headers});
    }

    sendGET_Classifications(orderField, orderBy) {
        const headers = new HttpHeaders({
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/Classifications/${orderField}/${orderBy}`, {headers});
    }

    sendGET_brand(orderField, orderBy) {
        const headers = new HttpHeaders({
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/Brands/${orderField}/${orderBy}`, {headers});
    }

    sendPOST_Product(messages) {
        return new Promise((resolve, reject) => {
            let raw = JSON.stringify(messages);
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
            });
            console.log("http: ", raw);

            return this.getDatos = this.http.post<any>(this.urlBase + '/Products/' + this.apikey + '/0/1', raw, {headers}).subscribe(data => {
                console.log(data);
                resolve(data)
            }, error => {
                console.log(error);
            });
        })
    }

    getProductsByUrl(data) {
        console.log('send data', data);
        const headers = new HttpHeaders({
            'api-key': this.apikey,
            'Limit': data.limit.toString(),
            'Offset': data.offset.toString(),
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/Products/${data.type}/${data.url}`, {headers});
    }

    getOneProductByUrl(data) {
        const headers = new HttpHeaders({
            'api-key': this.apikey,
            'Limit': data.limit.toString(),
            'Offset': data.offset.toString(),
            'Friendly-URL': data.urlComplete,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/Products/${data.type}/urlfull`, {headers});
    }

    async saveOrder(data) {
        return new Promise((resolve, reject) => {
            let raw = JSON.stringify(data);
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
            });
            this.http.post<Orders>(this.urlBase + '/Orders/' + this.apikey + '/1/0', raw, {headers})
                .subscribe(data => {
                    resolve(data)
                }, error => {
                    reject(error);
                });
        });
    }

    async sendPUT_Category(messages, id) {
        return new Promise((resolve, reject) => {
            let raw = JSON.stringify(messages);
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
            });
            // console.log("http: ", raw);

            this.getDatos = this.http.put<any>(this.urlBase + '/Categories/' + id + '/' + this.apikey + '/0/1', raw, {headers})
                .subscribe(data => {
                    console.log(data);
                    resolve(data);
                }, error => {
                    console.log(error);
                    reject(error);
                });
        })
    }

    updateUser(messages, uid) {
        console.log("http: ", messages);
        let raw = JSON.stringify(messages);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return this.http.put<User>(this.urlBase + '/Users/' + uid + '/' + this.apikey + '/1/1', raw, {headers});
    }

    getOrdersByUser(id, rows) {
        const headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>(this.urlBase + '/UserOrders/' + id + '/' + rows + '/' + this.apikey + '/1/1', {headers});
    }

    sendGET_BusinessPlace() {
        const headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>(this.urlBase + '/BusinessPlace/' + this.apikey + '/1/1', {headers});
    }

    async sendPUT_subcategory(message_post, id_subcategory) {
        return new Promise((resolve, reject) => {
            let raw = JSON.stringify(message_post);
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'api-key': this.apikey,
            });
            return this.getDatos = this.http.put<any>(this.urlBase + `/SubCategories/${id_subcategory}`, raw, {headers}).subscribe(data => {
                console.log(data['_body']);
                resolve(data['_body']);
            }, error => {
                reject(error);
                console.log(error);
            });
        })
    }

    async sendPUT_Classifications(message_post, id_clasification) {
        return new Promise((resolve, reject) => {
            let raw = JSON.stringify(message_post);
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'api-key': this.apikey,
            });
            return this.getDatos = this.http.put<any>(this.urlBase + `/Classifications/${id_clasification}`, raw, {headers}).subscribe(data => {
                console.log(data['_body']);
                resolve(data['_body']);
            }, error => {
                reject(error);
                console.log(error);
            });
        })
    }

    sendPUT_Product(messages, id, type = 0) {
        let end = type == 0 ? '/0/1' : '/0/1/666';
        return new Promise((resolve, reject) => {
            let raw = JSON.stringify(messages);
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
            });

            this.getDatos = this.http.put<any>(this.urlBase + '/Products/' + id + '/' + this.apikey + end, raw, {headers})
                .subscribe(data => {
                    console.info(data);
                    resolve(data);
                }, error => {
                    console.error(error);
                    reject(error);
                });
        })
    }

    newDataShoppingCart(messages) {
        return new Promise((resolve, reject) => {
            let raw = JSON.stringify(messages);
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'api-key': this.apikey,
            });
            return this.getDatos = this.http.post<any>(this.urlBase + '/DataShoppingCart/new/cart/user', raw, {headers}).subscribe(data => {
                console.log('print by service', data);
                resolve(data)
            }, error => {
                console.log(error);
            });
        })
    }

    async updateDataShoppingCartByCart(message_post, uid_cart) {
        return new Promise((resolve, reject) => {
            let raw = JSON.stringify(message_post);
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'api-key': this.apikey,
            });
            return this.getDatos = this.http.put<any>(this.urlBase + `/DataShoppingCart/write/cart/${uid_cart}`, raw, {headers}).subscribe(data => {
                console.log(data['_body']);
                resolve(data['_body']);
            }, error => {
                reject(error);
                console.log(error);
            });
        })
    }

    getDataShoppingCart(data) {
        const headers = new HttpHeaders({
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/DataShoppingCart/${data}`, {headers});
    }

    getDataShoppingCartByUser(user_uid) {
        const headers = new HttpHeaders({
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.get<any>(this.urlBase + `/DataShoppingCart/get/cart/${user_uid}/user`, {headers});
    }

    async sendPOST_Subcategory(messages) {
        let raw = JSON.stringify(messages);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.post<any>(this.urlBase + '/SubCategories', raw, {headers}).subscribe(data => {
            console.log(data['_body']);
        }, error => {
            console.log(error);
        });
    }

    getSettings() {
        const headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>(this.urlBase + '/SettingsApp/' + this.apikey + '/1/1', {headers});
    }

    async saveBrand(messages) {
        let raw = JSON.stringify(messages);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'api-key': this.apikey,
        });
        return this.getDatos = this.http.post<any>(this.urlBase + '/Brands', raw, {headers}).subscribe(data => {
            console.log(data['_body']);
        }, error => {
            console.log(error);
        });
    }

    getOrders() {
        let headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>('https://newrolling.com/migrate', {headers});
    }

    getOneOrder(id) {
        const headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>(this.urlBase + '/Orders/' + id + '/' + this.apikey + '/1/1', {headers});
    }

    updateOrder(messages, id) {
        return new Promise((resolve, reject) => {
            let raw = JSON.stringify(messages);
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
            });
            console.log("http: ", raw);

            return this.getDatos = this.http.put<any>(this.urlBase + '/Orders/' + id + '/' + this.apikey + '/1/1', raw, {headers})
                .subscribe(data => {
                    console.log(data);
                    resolve(data);
                }, error => {
                    console.log(error);
                });
        });
    }

    async sendEmail(messages) {
        console.log("http: ", messages);
        let raw = JSON.stringify(messages);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return this.http.post<AppNotifications>(this.urlBase + '/AppNotifications/' + this.apikey + '', raw, {headers})
            .subscribe(data => {
                console.log(data);
            }, error => {
                console.log(error);
            });
    }

    getOneProduct(id) {
        const headers = new HttpHeaders();
        return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + id + '/' + this.apikey + '/0/1', {headers});
    }

    async updateProduct(message_post, id_product) {
        return new Promise((resolve, reject) => {
            let raw = JSON.stringify(message_post);
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'api-key': this.apikey,
            });
            return this.getDatos = this.http.put<any>(this.urlBase + `/Products/` + id_product, raw, {headers}).subscribe(data => {
                console.log(data['_body']);
                resolve(data['_body']);
            }, error => {
                reject(error);
                console.log(error);
            });
        })
    }
}
