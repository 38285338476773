<div class="icon-nav">
    <ul>
        <!--<li class="onhover-div mobile-search search-widgets" id="search-widgets">
            <div (click)="searchToggle()">
                <img [defaultImage]="iconSearch" [lazyLoad]="iconSearch" class="img-fluid" alt="">
                <i class="ti-search"></i>
            </div>
            <div [hidden]="true" id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
                <div>
                    <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
                    <div class="overlay-content">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-12">
                                    <form>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Search a Product">
                                        </div>
                                        <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>-->
        <li (click)="verifySession()" class="onhover-div mobile-setting py-4">
            <div>
                <img [defaultImage]="iconUser" [lazyLoad]="iconUser" class="img-fluid" alt="">
                <i class="ti-user"></i>
            </div>
            <div *ngIf="showInfo" class="show-div setting">
                <h6>Welcome</h6>
                <ul>
                    <li *ngIf="!isLogged">
                        <a class="cursor-pointer" (click)="login()">login</a>
                    </li>
                    <ng-container *ngIf="isLogged">
                        <li>
                            <p><a style="text-transform: none" [routerLink]="'/pages/mi-cuenta'">{{ nameUser }}</a></p>
                        </li>
                        <li>
                            <a class="cursor-pointer" (click)="signOut()">Logout</a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </li>
        <li class="onhover-div mobile-cart py-4">
            <div>
                <a [routerLink]="['/shop/cart']">
                    <img [defaultImage]="iconCart" [lazyLoad]="iconCart" class="img-fluid" alt="">
                    <i class="ti-shopping-cart"></i>
                </a>
            </div>
            <span class="cart_qty_cls">{{ products.length }}</span>
            <ul class="show-div shopping-cart" *ngIf='!products.length'>
                <h5>Su carrito está vacío.</h5>
            </ul>
            <ul class="show-div shopping-cart" *ngIf='products.length'>
                <li *ngFor="let product of products">
                    <div class="media">
                        <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
                            <img class="me-3" [src]="product?.images[0].src" [alt]="product?.images[0].alt">
                        </a>
                        <div class="media-body">
                            <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
                                <h4>{{ product?.title }}</h4>
                            </a>
                            <h4>
                                <span>
                                  {{ product?.quantity }} x
                                    {{ product?.price * productService?.Currency.price | discount:product | currency:productService?.Currency.currency:'symbol' }}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div class="close-circle" (click)="removeItem(product)">
                        <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                </li>
                <li>
                    <div class="total">
                        <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
                    </div>
                </li>
                <li>
                    <div class="buttons">
                        <a [routerLink]="['/shop/cart']" class="view-cart">ver carrito</a>
                        <a [routerLink]="['/shop/checkout']" class="checkout">checkout</a>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
</div>
