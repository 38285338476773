<!--footer section -->
<footer [class]="class" [ngStyle]="{'background-image': 'url('+ bannerFooter +')'}">
    <div class="light-layout" *ngIf="newsletter">
        <div class="container">
            <section class="small-section border-section border-top-0">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="subscribe">
                            <div>
                                <h4>KNOW IT ALL FIRST!</h4>
                                <p>Never Miss Anything From Multikart By Signing Up To Our Newsletter. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
                            <div class="form-group mx-sm-3">
                                <input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
                            </div>
                            <button type="submit" class="btn btn-solid">subscribe</button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <section class="section-b-space light-layout">
        <div class="container">
            <div class="row footer-theme partition-f">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-title footer-mobile-title">
                        <h4>about</h4>
                    </div>
                    <div class="footer-contant">
                        <div class="footer-logo">
                            <img [src]="themeLogo" alt="logo">
                        </div>
                        <p style="color: {{ color }}" [textContent]="txtLeft"></p>
                        <div class="footer-social">
                            <ul>
                                <li *ngFor="let social of socialMedia"><a style="color: {{ color }}" [href]="social.url" target="_blank"><i style="color: {{ color }}" [class]="social.icon"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col" *ngFor="let item of middle">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>{{ item.title }}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li *ngFor="let link of item.quick_links"><a style="color: {{ color }}" [hidden]="!link.active" [href]="link.href">{{ link.label }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>Contáctanos</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
                                <li style="color: {{ color }}"><i style="color: {{ color }}" class="fa fa-phone"></i> {{ phone }} </li>
                                <li style="color: {{ color }}"><i style="color: {{ color }}" class="fa fa-envelope-o"></i>{{ email }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Designed by <a target="_blank" href="https://ianos.app">ianos.app</a></p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a><img src="assets/images/icon/visa.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                            </li>
                            <!--<li>
                                <a><img src="assets/images/icon/paypal.png" alt=""></a>
                            </li>-->
                            <li>
                                <a><img src="assets/images/icon/american-express.png" alt=""></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->
