import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class WompiService {

    wompi = environment.wompi;
    UrlBase = environment.urlBaseWompy;
    apikey = environment.apikey;
    getDatos;

    constructor(
        private http: HttpClient,
    ) {
    }

    wompiApiAuthentication() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                "api-key": this.apikey
            });

            this.http.post(this.UrlBase + "/token", this.wompi, {headers: headers})
                .subscribe(data => {
                    console.log("wompiApiAuthentication: ", data);
                    resolve(data);
                }, error => {
                    console.log("Error #:", error);
                    reject(error);
                });
        })
    }

    EnlacePago(data) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                "api-key": this.apikey
            });
            console.log("http: ", data);
            // var raw = JSON.stringify(data);

            this.http.post(this.UrlBase + "/link/payment", data, {headers: headers})
                .subscribe(data => {
                    console.log("EnlacePago: ", data);
                    resolve(data);
                }, error => {
                    console.log("Error EnlacePago #:", error);
                    reject(error);
                });
        })
    }

    EnlacePagoId(data) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                "api-key": this.apikey
            });
            console.log("http: ", data);
            // var raw = JSON.stringify(data);

            this.http.post(this.UrlBase + "/link/payment/" + data.id, {token: data.token}, {headers: headers})
                .subscribe((res: any) => {
                    console.log("EnlacePagoId: ", res);
                    var data_wompy = JSON.parse(res.body);
                    resolve(data_wompy);
                }, error => {
                    console.log("Error EnlacePagoId #:", error);
                    reject(error);
                });
        })
    }
}
