import {Component, PLATFORM_ID, Inject} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {map, delay, withLatestFrom} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {HttpService} from "./services/http.service";
import {BrowserService} from "./services/browser.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    // For Progressbar
    loaders = this.loader.progress$.pipe(
        delay(1000),
        withLatestFrom(this.loader.progress$),
        map(v => v[1]),
    );

    themeDeafult: string = '#FF4C3B';
    maintenance_page: boolean = false;
    Year = new Date().getFullYear();
    logo: string = '';
    txtLabel: string = '';
    browserName: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private loader: LoadingBarService, translate: TranslateService,
        private service: HttpService,
        private browserService: BrowserService,
    ) {
        this.browserName = browserService.getBrowserName();
        console.log('browserName',this.browserName)
        if (isPlatformBrowser(this.platformId)) {
            translate.setDefaultLang('en');
            translate.addLangs(['en', 'fr']);
        }
        this.service.sendGET_SettingsApp('MaintenancePage').subscribe((res) => {
            console.log('MaintenancePage',res )
            this.maintenance_page = res.maintenance_page;
            this.logo             = res.image;
            this.txtLabel         = res.text_label;
        });
        this.service.sendGET_SettingsApp('Store').subscribe((res) => {
            console.log('themeDeafult',res.themeDefault )
            this.themeDeafult = res.themeDeafult.toLocaleUpperCase();
        });
    }

    ngOnInit(): void {
        document.documentElement.style.setProperty('--theme-deafult', this.themeDeafult );
    }

}
