import {Component, OnInit, Input, HostListener, Output, EventEmitter} from '@angular/core';
import {HttpService} from "../../../services/http.service";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-header-four',
    templateUrl: './header-four.component.html',
    styleUrls: ['./header-four.component.scss']
})
export class HeaderFourComponent implements OnInit {

    @Input() class: string = 'header-2 header-6';
    @Input() themeLogo: string = environment.loadGif; // Default Logo
    @Input() topbar: boolean = true; // Default True
    @Input() sticky: boolean = false; // Default false

    public stick: boolean = false;
    public color: string = '';
    @Output() resultSearch : EventEmitter<any> = new EventEmitter<any>();
    public contentSearch: string = '';
    public contentSearch2: string = '';
    public boxMenu: boolean = false;

    constructor(
        private service: HttpService,
        private router: Router,
    ) {
        this.service.sendGET_SettingsApp('Store').subscribe((res) => {
            this.themeLogo = res.header.logo.default;
            this.color      = res.primaryColor;
        });
    }

    ngOnInit(): void {
        this.boxMenu = window.innerWidth < 1200;
    }

    // @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        (number >= 150 && window.innerWidth > 400) ? this.stick = true : this.stick = false;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        let width = event.target.innerWidth;
        this.boxMenu = width < 1200;
    }

    search() {
        console.log('search', this.contentSearch);
        // this.resultSearch.emit(this.contentSearch);
        // this.router.navigate(['/pages/resultado',this.contentSearch.replaceAll(' ','-')]).then( res => {
        // window.location.href = `/pages/resultado/${this.contentSearch.replaceAll(' ','-')}`;
        this.router.navigate(['/pages/resultado',this.contentSearch.replaceAll(' ','-')]).then( res => {
            Swal.fire({
                icon: 'info',
                title: 'Buscando producto, por favor espere un momento',
                showConfirmButton: false,
                timer: 2e3
            });
        })
    }

}
