<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="px-4">
    <div class="row">
      <div class="col-sm-3">
        <div class="page-title">
          <h2>{{ title }}</h2>
        </div>
      </div>
      <div *ngIf="breadcrumbDynamic.length == 0" class="col-sm-6">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/home'">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ breadcrumb }}</li>
          </ol>
        </nav>
      </div>
      <div *ngIf="breadcrumbDynamic.length > 0" class="col">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <ng-container *ngFor="let item of breadcrumbDynamic; index as i;">
              <li *ngIf="breadcrumbDynamic.length-1 != i" class="breadcrumb-item">
                <a *ngIf="i == 0" [routerLink]="item.url">{{ item.name }}</a>
                <a *ngIf="i > 0"   [routerLink]="pathAbsolute[i].path">{{ item.name }}</a>
<!--                <a *ngIf="i > 0" [routerLink]="item.url">{{ item.name }}</a>-->
              </li>
              <li *ngIf="breadcrumbDynamic.length-1 == i" class="breadcrumb-item active" aria-current="page"> {{ item.name }} </li>
            </ng-container>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
