<app-breadcrumb [title]="'Wishlist'" [breadcrumb]="'Wishlist'"></app-breadcrumb>
<!--section start-->
<section class="wishlist-section section-b-space">
  <div class="container">
    <div class="col-md-12 empty-cart-cls text-center" *ngIf='!products.length'>
      <img src="assets/images/empty-wishlist.png" alt="Empty cart" title="Emprt cart">
      <h3 class="mt-4"><b>Wishlist is Empty</b></h3>
      <h4>Explore more shortlist some items.</h4>
    </div>
    <div class="row" *ngIf='products.length'>
      <div class="col-sm-12">
        <table class="table cart-table table-responsive-xs">
          <thead>
            <tr class="table-head">
              <th scope="col">image</th>
              <th scope="col">product name</th>
              <th scope="col">price</th>
              <th scope="col">availability</th>
              <th scope="col">action</th>
            </tr>
          </thead>
          <tbody *ngFor="let product of products">
            <tr>
              <td>
                  <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
                    <img [src]="product.images[0].src" [alt]="product.images[0].alt">
                  </a>
              </td>
              <td>
                  <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">{{ product.title | titlecase }}</a>
                  <div class="mobile-cart-content row">
                    <div class="col">
                      <p>{{ product.stock > 0 ? 'in stock' : 'out of stock' }}</p>
                    </div>
                    <div class="col">
                      <h2 class="td-color">
                          {{ (product.price | discount:product) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                      </h2>
                    </div>
                    <div class="col">
                      <h2 class="td-color">
                        <a href="javascript:void(0)" (click)="removeItem(product)" class="icon me-1">
                          <i class="ti-close"></i>
                        </a>
                        <a [routerLink]="['/shop/cart']" (click)="addToCart(product)" class="cart">
                          <i class="ti-shopping-cart"></i>
                        </a>
                      </h2>
                    </div>
                  </div>
              </td>
              <td>
                  <h2>
                    {{ (product.price | discount:product) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                  </h2>
              </td>
              <td>
                  <p>{{ product.stock > 0 ? 'in stock' : 'out of stock' }}</p>
              </td>
              <td>
                  <a href="javascript:void(0)" (click)="removeItem(product)" class="icon me-3">
                    <i class="ti-close"></i>
                  </a>
                  <a href="javascript:void(0)" (click)="addToCart(product)" class="cart">
                    <i class="ti-shopping-cart"></i>
                  </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row wishlist-buttons" *ngIf='products.length'>
      <div class="col-12">
        <a [routerLink]="['/shop/collection/left/sidebar']" class="btn btn-solid">continue shopping</a>
        <a [routerLink]="['/shop/checkout']" class="btn btn-solid">check out</a>
      </div>
    </div>
  </div>
</section>
<!--section end-->