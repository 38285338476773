import {Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
// import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import {environment} from '../../../environments/environment';
import {Product} from "../../shared/classes/product";
import {ProductService} from "../../shared/services/product.service";
import {OrderService} from "../../shared/services/order.service";
import {WompiService} from "../../services/wompi.service";
import {Router} from "@angular/router";
import {User} from "../../shared/classes/user";
import {AuthService} from "../../shared/services/auth.service";
import {ToastrService} from "ngx-toastr";
import {HttpService} from "../../services/http.service";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {isPlatformBrowser} from "@angular/common";
import Swal from "sweetalert2";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Order, Orders} from "../../shared/classes/order";

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
    @ViewChild("pasarelaWompi") pasarelaWompi: TemplateRef<any>;

    user = new User();
    public checkoutForm: UntypedFormGroup;
    public products: Product[] = [];
    // public payPalConfig ? : IPayPalConfig;
    public payment: string = 'Wompi';
    public shipping: string = '';
    public amount: any;
    private data_wompi: any;
    public verify: boolean = false;
    private orderId: number = localStorage['orderId'];
    private isLogged: boolean = false;
    methodShipping: any [] = [];
    methodInvoicing: any [] = [];
    sucursales: any [] = [];
    invoicing: string = '';
    sucursal: string = '';
    metodoPago: any [] = [];
    pago: string = '';
    urlPayment: SafeResourceUrl;
    isWompi: boolean = false;
    closeResult: string;
    txtMessage:         string = '';
    mainStatusOrder:    string = '';
    mainBadgeColor:     string = '';
    finalBadgeColor:    string = '';
    finalStatusOrder:   string = '';
    txtSuccess:         string = '';

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private fb: UntypedFormBuilder,
        public productService: ProductService,
        public wompi: WompiService,
        private orderService: OrderService,
        private auth: AuthService,
        private toastrService: ToastrService,
        private service: HttpService,
        private modalService: NgbModal,
        private router: Router,
        private domSatizer: DomSanitizer,
    ) {
        this.productService.updateCart();
        this.checkoutForm = this.fb.group({
            firstname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
            lastname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
            phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
            email: ['', [Validators.required, Validators.email]]
            // address: ['', [Validators.required, Validators.maxLength(50)]],
            // country: ['', Validators.required],
            // town: ['', Validators.required],
            // state: ['', Validators.required],
            // postalcode: ['', Validators.required]
        })
        this.auth.getUser2().then(async userLocal => {
            this.isLogged = true;
            console.log("userLocal: ", userLocal);
            this.user = userLocal;
            console.log("this.user.emailVerified: ", this.user.emailVerified);

            if (!this.user.emailVerified) {
                this.auth.logout().then((e) => {
                    this.router.navigate(['/pages/login']).then(() => {
                        Swal.fire(
                            'Estimado usuario',
                            'Por motivos de seguridad, es necesario validar su cuenta para poder continuar con el proceso de compra, ' +
                            'se le ha enviado un correo electronico para validar su cuenta, favor de verificar e inciar sesión nuevamente',
                            'info'
                        )
                        this.auth.sendEmailVerification().then(r => console.log('then sendEmailVerification', r)).catch(err => console.error('catch sendEmailVerification', err));
                    });
                }).catch((e) => {
                    console.log('catch signOut', e);
                });
            }

            this.checkoutForm.value.firstname = this.user.firstName
            this.checkoutForm.value.lastname = this.user.lastName
            // @ts-ignore
            this.checkoutForm.value.phone = this.user.phone?.internationalNumber || this.user.phone;
            this.checkoutForm.value.email = this.user.email
            console.log("this.checkoutForm.value: ", this.checkoutForm.value);
        }).catch(e => {
            this.router.navigate(['/pages/login']).then(() => this.toastrService.info('Estimado usuario, es necesario iniciar sesión para continuar con el proceso de compra.'));
        })

    }

    ngOnInit(): void {
        this.productService.cartItems.subscribe(response => {
            console.log('cartItems', response)
            this.products = response
        });
        this.getTotal.subscribe(amount => this.amount = amount);
        this.initConfig();

        this.service.sendGET_SettingsApp('General').subscribe(res => {
            console.log('response General', res);
            this.metodoPago = res.method_payment;
        })
        this.service.sendGET_SettingsApp('Shipping').subscribe(res => {
            console.log('response shipping', res);
            this.methodShipping = res.method;
        })
        this.service.sendGET_SettingsApp('Invoicing').subscribe(res => {
            console.log('response Invoicing', res);
            this.methodInvoicing = res.method;
        })
        // this.service.sendGET_SettingsApp('Payment').subscribe(res => {
        //     console.log('response Invoicing', res);
        //     this.metodoPago = res.method;
        // })
        this.service.sendGET_BusinessPlace().subscribe(res => {
            console.log('response sucursale', res);
            this.sucursales = res;
        })
    }

    public get getTotal(): Observable<number> {
        return this.productService.cartTotalAmount();
    }

    // Stripe Payment Gateway
    stripeCheckout() {
        var handler = (<any>window).StripeCheckout.configure({
            key: environment.stripe_token, // publishble key
            locale: 'auto',
            token: (token: any) => {
                // You can access the token ID with `token.id`.
                // Get the token ID to your server-side code for use.
                this.orderService.createOrder(this.products, this.checkoutForm.value, token.id, this.amount);
            }
        });
        handler.open({
            name: 'Multikart',
            description: 'Online Fashion Store',
            amount: this.amount * 100
        })
    }

    // Paypal Payment Gateway
    private initConfig(): void {
        // this.payPalConfig = {
        //     currency: this.productService.Currency.currency,
        //     clientId: environment.paypal_token,
        //     createOrderOnClient: (data) => < ICreateOrderRequest > {
        //       intent: 'CAPTURE',
        //       purchase_units: [{
        //           amount: {
        //             currency_code: this.productService.Currency.currency,
        //             value: this.amount,
        //             breakdown: {
        //                 item_total: {
        //                     currency_code: this.productService.Currency.currency,
        //                     value: this.amount
        //                 }
        //             }
        //           }
        //       }]
        //   },
        //     advanced: {
        //         commit: 'true'
        //     },
        //     style: {
        //         label: 'paypal',
        //         size:  'small', // small | medium | large | responsive
        //         shape: 'rect', // pill | rect
        //     },
        //     onApprove: (data, actions) => {
        //         this.orderService.createOrder(this.products, this.checkoutForm.value, data.orderID, this.getTotal);
        //         console.log('onApprove - transaction was approved, but not authorized', data, actions);
        //         actions.order.get().then(details => {
        //             console.log('onApprove - you can get full order details inside onApprove: ', details);
        //         });
        //     },
        //     onClientAuthorization: (data) => {
        //         console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        //     },
        //     onCancel: (data, actions) => {
        //         console.log('OnCancel', data, actions);
        //     },
        //     onError: err => {
        //         console.log('OnError', err);
        //     },
        //     onClick: (data, actions) => {
        //         console.log('onClick', data, actions);
        //     }
        // };
    }

    pagoWompi(content) {
        Swal.fire({
            icon: 'info',
            title: 'Procesando pago, espere un momento por favor',
            showConfirmButton: false,
            backdrop: 'static',
            allowOutsideClick: false,
        });
        console.log('this.shipping', this.shipping);

        // return false;
        let products = "";
        let createOrder = this.orderService.createOrder(this.products, this.checkoutForm.value, this.orderId, this.amount);
        console.log('createOrder', createOrder);

        createOrder.checkoutItems.product.forEach(element => {
            products = products.concat(element.product_name + ' || ');
        })

        let data = {
            token: "",
            identificadorEnlaceComercio: "Pedido # ",
            monto: createOrder.checkoutItems.totalAmount,
            nombreProducto: products,
            configuracion: {
                esMontoEditable: false,
                // urlRedirect: 'http://localhost:8100/checkout-details/'+code,
                // urlRetorno: 'http://localhost:8100/checkout-details/',
                esCantidadEditable: false,
                duracionInterfazIntentoMinutos: 30,
                emailsNotificacion: createOrder.checkoutItems.shippingDetails.email,
                notificarTransaccionCliente: true
            }
        };
        console.log("data: ", data);

        /*this.wompi.wompiApiAuthentication().then((toke: any) => {
            console.log("wompiApiAuthentication: ", toke);
            data.token = toke.access_token;
            this.wompi.EnlacePago(data).then((res: any) => {
                console.log("EnlacePago: ", res.body);
                let data_wompi = res.body;
                this.data_wompi = data_wompi;
                // this.data_wompi.token = toke.access_token;
                // window.location.href = data_wompi.urlPayment;
                window.open(data_wompi.urlPayment, '_blank');
                // console.log("data_wompi: ", this.data_wompi);
                this.verify = true;
            });
        }).catch((error) => {
            console.log('error send data', error);
        });*/

        this.wompi.wompiApiAuthentication().then((toke: any) => {
            console.log("response wompiApiAuthentication: ", toke);
            data.token = toke.access_token;
            this.wompi.EnlacePago(data).then((res: any) => {
                console.log("response EnlacePago: ", res);
                if (res.success){
                    this.data_wompi = res;
                    this.data_wompi.access_token = toke.access_token;
                    this.verify = true;
                    this.urlPayment = this.domSatizer.bypassSecurityTrustResourceUrl(res.urlPayment);
                    // window.open(res.urlPayment, '_blank');
                    this.orderService.processOrder(this.user, 666);
                    Swal.close();
                    this.openVerticallyCentered(content);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ha ocurrido un error a la hora de generar el pago, intentelo de nuevo por favor',
                    });
                }
            });
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error a la hora de procesar el pago, intentelo de nuevo por favor',
            });
        });

    }

    confirmParymentWompi() {
        Swal.fire({
            icon: 'info',
            title: 'Confirmando pago, espere un momento por favor',
            showConfirmButton: false,
            backdrop: 'static',
            allowOutsideClick: false,
        });
        let data = {
            id: this.data_wompi.idLink,
            token: this.data_wompi.access_token,
        }
        console.log("data: confirm ", data);
        this.wompi.EnlacePagoId(data).then((confirm: any) => {
            console.log("EnlacePagoId: ", confirm);
            if (confirm.estaProductivo) {
                if (confirm.esAprobada) {
                    Swal.fire('Exito', 'Pago procesado correctamente', 'success').then(res => {
                        localStorage.removeItem("cartItems");
                        localStorage.removeItem("orderId");
                        // localStorage.removeItem("order_uid");
                        // this.router.navigate(['/shop/checkout/success', this.orderId]);
                        window.location.href = '/shop/checkout/success/' + localStorage.getItem('order_uid');
                    })
                } else {
                    Swal.fire('Error', 'No hemos podido verificar el pago real realizado en la pasarela de pago.', 'error')
                }
            } else {
                Swal.fire('Exito', 'Pago procesado correctamente', 'success').then(res => {
                    localStorage.removeItem("cartItems");
                    localStorage.removeItem("orderId");
                    // localStorage.removeItem("order_uid");
                    // this.router.navigate(['/shop/checkout/success', this.orderId]);
                    window.location.href = '/shop/checkout/success/' + localStorage.getItem('order_uid');
                })
            }
        })
    }

    metodoEnvio(event) {
        console.log('evento', event)
        localStorage.setItem("shipping", event);
    }

    documentoGenerar(event) {
        console.log('evento', event)
        localStorage.setItem("invoicing", event);
    }

    sucursalRetiro(event) {
        console.log('evento', event)
        let select = this.sucursales.filter(item => item.company_dba_name == event);
        console.log('select sucursales', select);
        localStorage.setItem("sucursal", event);
        localStorage.setItem("commerce_direction", select[0].street_address);
    }

    paymentMethod(event) {
        console.log('evento', event)
        let [select]            = this.metodoPago.filter(item => item.name == event);
        this.isWompi            = select.isWompi;
        this.txtMessage         = select?.txtMessage;
        this.mainStatusOrder    = select?.mainStatusOrder;
        this.mainBadgeColor     = select?.mainBadgeColor;
        this.finalBadgeColor    = select?.finalBadgeColor;
        this.finalStatusOrder   = select?.finalStatusOrder;
        this.txtSuccess         = select?.txtSuccess;
        console.log("select payment", select);
        let txtPayment = this.isWompi ? 'Wompi' : event;
        localStorage.setItem('mainBadgeColor',  this.mainBadgeColor);
        localStorage.setItem('mainStatusOrder', this.mainStatusOrder);
        localStorage.setItem('finalStatusOrder', this.finalStatusOrder);
        localStorage.setItem('finalBadgeColor', this.finalBadgeColor);
        localStorage.setItem('txtSuccess', this.txtSuccess);
        localStorage.setItem("payment", txtPayment);
    }

    async confirmarPedido() {
        let templateContent;
        templateContent = document.getElementById("table-invoice").innerHTML;
        localStorage.setItem('templateContent', templateContent);
        Swal.fire({
            icon: 'info',
            title: 'Procesando pedido, espere un momento por favor',
            showConfirmButton: false,
            backdrop: 'static',
            allowOutsideClick: false,
        })
        this.orderService.createOrder(this.products, this.checkoutForm.value, this.orderId, this.amount);
        this.orderService.processOrder(this.user, 666);
        // if (localStorage.getItem("payment") != 'Wompi') this.router.navigate(['/shop/checkout/success', this.orderId]);
        // if (localStorage.getItem("payment") != 'Wompi') this.router.navigate(['/shop/checkout/success', this.orderId]);

    }

    openModal() {
        if (isPlatformBrowser(this.platformId)) { // For SSR
            this.modalService.open(this.pasarelaWompi, {
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                centered: true,
                windowClass: 'bd-example-modal-md theme-modal agem'
            }).result.then((result) => {
                `Result ${result}`
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    openVerticallyCentered(content) {
        this.modalService.open(content, {
            centered: true,
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'modal-2',
            animation: true
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            console.log('close modal by pressing ESC');
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            console.log('close modal by clicking on a backdrop');
            return 'by clicking on a backdrop';
        } else {
            console.log(`close modal with: ${reason}`);
            return `with: ${reason}`;
        }
    }

    cancelPaymentWompi() {
        let order_uid = localStorage.getItem('order_uid');
        console.log('order_uid', order_uid);
        if (order_uid != '') {
            this.service.getOneOrder(order_uid).subscribe(res => {
                console.log('response getOneOrder', res)
                let message = res;
                message.orders_status_uid = 'Payment Failed';
                message.badgeColor = 'danger';
                this.service.updateOrder(message, message.id).then(res1 => {
                    this.modalService.dismissAll('Se arrepintio');
                    // localStorage.removeItem('order_uid');
                    this.getStock(message).then(stock => {
                        setTimeout(() => {
                            console.log("update: ", stock);
                            this.updateStock(stock).then(res2 => {
                                console.log("update: ", stock);
                                window.location.href = '/shop/cart';
                            })
                        }, 1e3);
                    });

                    this.router.navigate(['/shop/cart']);
                    console.info('update order', res1)
                }).catch(err => {
                    console.error('error update order', err)
                })
            })
        }
    }

    getStock(order) {
        let update = [];
        let new_products_stock;
        return new Promise((resolve, reject) => {
            order.items.forEach(element => {
                this.service.getOneProduct(element.uid).subscribe(res => {
                    console.log("sendGET_One_Product: ", res.products_stock, " + ", res.product_name);
                    new_products_stock = res.products_stock + element.quantity;
                    res.products_stock = new_products_stock;
                    if (new_products_stock > 0) {
                        update.push(res);
                    } else {
                        res.product_availability = 'Stock';
                        res.product_background_availability = 'success';
                        update.push(res);
                    }
                });
            });
            resolve(update);
        })
    }

    updateStock(stock) {
        return new Promise((resolve, reject) => {
            stock.forEach(element => {
                this.service.sendPUT_Product(element, element.id).then(res => {
                    console.log("sendPUT_Product: ", res);
                });
            });
            resolve(stock)
        })
    }
}
