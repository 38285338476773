import { Product } from './product';
import {Observable} from "rxjs";

// Order
export interface Order {
    shippingDetails?: any;
    product?: Product;
    orderId?: any;
    totalAmount?: any;
}

// Orders
export interface Orders {
    is_notified_commerce: boolean;
    status_user: boolean;
    yet_lat: string;
    subtotalForYelou: number;
    commerce_name: string;
    lat_delivery_end: number;
    is_notified_client: boolean;
    rate_service_comment: string;
    dedication: string;
    place_delivery_user: string;
    created_at_formatted: string;
    sucursal: string;
    who_receives: string;
    badgeColor: string;
    is_delivery: true;
    track_positions: [];
    change_quantity: number;
    totalInCents: number;
    btc_checkout_id: string;
    yet_lng: string;
    cash_quantity: number;
    deleted_at: null;
    pay_bitcoin_yet: boolean;
    zone: string;
    status_uid: string;
    discount_percent: number;
    phone: number;
    direction: string;
    order_active: true;
    location: string;
    is_bitcoin: boolean;
    isGift: boolean;
    amount: number;
    lon_delivery_start: number;
    is_order_schedule: boolean;
    manager_uid: string;
    date_delivery_start: null;
    total: number;
    tax: number;
    tip_porcent: string;
    tip_amount: number;
    is_notified_yet: boolean;
    items: any[];
    yet_image: string;
    is_card: boolean;
    status_driver: string;
    discount: number;
    orders_status_uid: string;
    note: string;
    uid: string;
    order_createday: Date;
    commerce_uid: string;
    user_uid: string;
    rate_service: string;
    updated_at: null;
    date_delivery_end: null;
    sucursal_uid: string;
    yet_uid: string;
    customer_payment_uid: string;
    yet_phone: string;
    commerce_image: string;
    date_cooked: null;
    municipality: string;
    deparment: string;
    subtotalForRide: number;
    payment_gateway_uid: string;
    commerce_direction: string;
    place_delivery_name: string;
    lng_delivery_end: number;
    need_invoice: boolean;
    lat_delivery_start: number;
    order_schedule_format: Object;
    yet_name: string;
    stripe_transaction: Array<any>;
    commerce_ubication: any;
    user_ubication: any;
    wompy_transaction:  Array<any>;
    waytopay: string;
}

export class Orders {
    public is_notified_commerce: boolean;
    public status_user: boolean;
    public yet_lat: string;
    public subtotalForYelou: number;
    public commerce_name: string;
    public lat_delivery_end: number;
    public is_notified_client: boolean;
    public rate_service_comment: string;
    public dedication: string;
    public place_delivery_user: string;
    public created_at_formatted: string;
    public sucursal: string;
    public who_receives: string;
    public badgeColor: string = 'primary';
    public is_delivery: true;
    public track_positions: [];
    public change_quantity: number;
    public totalInCents: number;
    public btc_checkout_id: string;
    public yet_lng: string;
    public cash_quantity: number;
    public deleted_at: null;
    public pay_bitcoin_yet: boolean;
    public zone: string;
    public status_uid: string;
    public discount_percent: number;
    public phone: number;
    public direction: string;
    public order_active: true;
    public location: string;
    public is_bitcoin: boolean;
    public isGift: boolean;
    public amount: number;
    public lon_delivery_start: number;
    public is_order_schedule: boolean;
    public manager_uid: string;
    public date_delivery_start: null;
    public total: number;
    public tax: number;
    public tip_porcent: string;
    public tip_amount: number;
    public is_notified_yet: boolean;
    public items: any[];
    public yet_image: string;
    public is_card: boolean;
    public status_driver: string;
    public discount: number;
    public orders_status_uid: string;
    public note: string;
    public uid: string;
    public order_createday: Date;
    public commerce_uid: string;
    public user_uid: string;
    public rate_service: string;
    public updated_at: null;
    public date_delivery_end: null;
    public sucursal_uid: string;
    public yet_uid: string;
    public customer_payment_uid: string;
    public yet_phone: string;
    public commerce_image: string;
    public date_cooked: null;
    public municipality: string;
    public deparment: string;
    public subtotalForRide: number;
    public payment_gateway_uid: string;
    public commerce_direction: string;
    public place_delivery_name: string;
    public lng_delivery_end: number;
    public need_invoice: boolean;
    public lat_delivery_start: number;
    public order_schedule_format: Object;
    public yet_name: string;
    public stripe_transaction: Array<any>;
    public commerce_ubication: any;
    public user_ubication: any;
    public wompy_transaction:  Array<any>;
    public waytopay: string;
    public invoicing: string;
    shipping: string;
    isWeb: boolean = true;

    constructor(){}
}

export class DataShoppingCart {
    public active: boolean = true;
    public items: any[];
    public user_uid: string;
    public order_uid: string;
    public total: number;
    uid: any;

    constructor(){}
}


