<app-breadcrumb [title]="'Profile'" [breadcrumb]="'Profile'"></app-breadcrumb>
<!-- personal deatail section start -->
<section class="contact-page pt-1 register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="card text-dark bg-light mb-3">
                    <div class="card-header">
                        <div class="row">
                            <div class="col d-grid align-items-center"><b class="fs-5"> Datos personales</b></div>
                            <div class="col d-flex justify-content-end">
                                <button *ngIf="showBtnSave" class="btn btn-sm btn-solid" (click)="updateUser()" type="submit">Guardar</button>
                                <ng-container *ngIf="user.direction.length > 0">
                                    <button [hidden]="showNewAddress" (click)="newAddress()" class="btn btn-info mx-1" [innerText]="'Nueva dirección' "></button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form class="">
                            <div class="row">
                                <div class="col-md-3">
                                    <label for="name">First Name</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="user.firstName" name="name" id="name" placeholder="Enter Your name" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="email">Last Name</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="user.lastName" id="last-name" name="last-name" placeholder="Email" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="review">Phone number</label>
                                    <input type="number" class="form-control form-control-sm" [(ngModel)]="user.phone" id="review" name="review" placeholder="Enter your number" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="email">Email</label>
                                    <input [readOnly]="true" type="text" class="form-control form-control-sm" [(ngModel)]="user.email" id="email" name="email" placeholder="Email" required="">
                                </div>
                                <div [hidden]="true" class="col-md-12">
                                    <label for="review">Write Your Message</label>
                                    <textarea class="form-control mb-0" placeholder="Write Your Message" id="exampleFormControlTextarea1" rows="6"></textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card text-dark bg-light mb-3">
                    <div class="card-header"><b class="fs-5"> Datos de la empresa</b></div>
                    <div class="card-body">
                        <form class="">
                            <div class="row">
                                <div class="col-md-3">
                                    <label for="nameCommercial">Nombre comercial</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="addressCompany.nameCommercial" name="nameCommercial" id="nameCommercial" placeholder="" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="socialReason">Razón social</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="addressCompany.socialReason" name="socialReason" id="socialReason" placeholder="" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="nit">NIT</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="addressCompany.nit" name="nit" id="nit" placeholder="" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="nrc">NRC</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="addressCompany.nrc" name="nrc" id="nrc" placeholder="" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="address">Dirección</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="addressCompany.address" name="address" id="address" placeholder="" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="department">Departamento</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="addressCompany.department" name="department" id="department" placeholder="" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="municipality">Municipio</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="addressCompany.municipality" name="municipality" id="municipality" placeholder="" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="phone">Teléfono</label>
                                    <input type="number" class="form-control form-control-sm" [(ngModel)]="addressCompany.phone" name="phone" id="phone" placeholder="" required="">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="user.direction.length > 0" class="col-sm-12">
                <ng-container *ngFor="let item of user.direction; let i = index;">
                    <div class="card text-dark bg-light mb-3">
                        <div class="card-header">
                            <div class="row">
                                <div class="col d-grid align-items-center"><b class="fs-5"> {{item.type}} Dirección</b></div>
                                <div class="col d-flex justify-content-end">
                                    <button (click)="changeStatus(i)" class="btn mx-1" [ngClass]="item.is_default ? 'btn-danger' : 'btn-success'" [innerText]="item.is_default ? 'Deshabilitar Dirección principal' : 'Activar Dirección principal '"></button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <form class="">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="type">decription</label>
                                        <input type="text" class="form-control form-control-sm" [(ngModel)]="item.type" id="type{{i}}" name="type" placeholder="address type" required="">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="direction">Address *</label>
                                        <input type="text" class="form-control form-control-sm" [(ngModel)]="item.direction" id="direction{{i}}" name="direction" placeholder="Address" required="">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="zipCode">Zip Code *</label>
                                        <input type="text" class="form-control form-control-sm" [(ngModel)]="item.zipCode" id="zipCode{{i}}" name="zipCode" placeholder="zip-code" required="">
                                    </div>
                                    <div class="col-md-3 select_input">
                                        <label for="country">Country *</label>
                                        <select id="country{{i}}" name="country" [(ngModel)]="item.country" class="form-control form-control-sm" size="1">
                                            <option value="">Select country</option>
                                            <option value="US">US</option>
                                            <option value="El Salvador">El Salvador</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="city">City *</label>
                                        <input type="text" [(ngModel)]="item.city" class="form-control form-control-sm" id="city{{i}}" name="city" placeholder="City" required="">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="state">Region/State *</label>
                                        <input type="text" class="form-control form-control-sm" [(ngModel)]="item.state" id="state{{i}}" name="state" placeholder="Region/state" required="">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div #form *ngIf="showNewAddress || user.direction.length == 0" class="col-lg-12">
                <div class="card text-dark bg-light mb-3">
                    <div class="card-header"><b class="fs-5"> Nueva dirección de envío</b></div>
                    <div class="card-body">
                        <form class="">
                            <div class="row">
                                <div class="col-md-3">
                                    <label for="type">decription</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="address.type" id="type" name="type" placeholder="address type" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="direction">Address *</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="address.direction" id="direction" name="direction" placeholder="Address" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="zipCode">Zip Code *</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="address.zipCode" id="zipCode" name="zipCode" placeholder="zip-code" required="">
                                </div>
                                <div class="col-md-3 select_input">
                                    <label for="country">Country *</label>
                                    <select id="country" name="country" [(ngModel)]="address.country" class="form-control form-control-sm" size="1">
                                        <option value="">Select country</option>
                                        <option value="US">US</option>
                                        <option value="El Salvador">El Salvador</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="city">City *</label>
                                    <input type="text" [(ngModel)]="address.city" class="form-control form-control-sm" id="city" name="city" placeholder="City" required="">
                                </div>
                                <div class="col-md-3">
                                    <label for="state">Region/State *</label>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="address.state" id="state" name="state" placeholder="Region/state" required="">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->
