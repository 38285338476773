import {Injectable} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/auth";
import {User} from "../classes/user";
import {Subscription} from "rxjs";
import {FirestoreService} from "../../services/firestore.service";
import {EventsService} from "./events.service";
import {Platform} from "@angular/cdk/platform";
import * as firebase from "firebase";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    confirmationResult: firebase.auth.ConfirmationResult;
    public user: User;
    private fbSubscription: Subscription;
    private fsSubscription: Subscription;

    constructor(
        private afAuth: AngularFireAuth,
        private platform: Platform,
        private firestore: FirestoreService,
        private events: EventsService,
    ) {
    }

    public loginWithEmail(email: string, password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.signInWithEmailAndPassword(email, password).then(res => {
                resolve(res);
                console.log('loginWithEmail');
            }).catch(err => {
                reject(err);
            });
        });
    }

    async getUser() {
        if (this.user) {
            console.log('auth getUserLocal() : ', this.user);
            return this.user;
        } else {
            if (this.fbSubscription) await this.fbSubscription.unsubscribe();
            this.fbSubscription = await this.afAuth.authState.subscribe((userFirebase: firebase.User) => {
                if (userFirebase) {
                    console.log('auth getUserFirebase() : ', userFirebase);
                    this.firestore.get('Users/' + userFirebase.uid).then(ref => {
                        console.log('auth get() : ', ref);
                        if (this.fsSubscription) this.fsSubscription.unsubscribe();
                        this.fsSubscription = ref.valueChanges().subscribe((userLocal: User) => {
                            this.user = userLocal;
                            console.log('auth getUserFirestore() : ', this.user);
                            return this.user;
                        });
                    }).catch((e) => {
                        return e;
                    });
                } else {
                    return false;
                }
            });
        }
    }

    public getUser2(): Promise<User> {
        return new Promise((resolve, reject) => {
            if (this.user) {
                console.log('auth getUserLocal() : ', this.user);
                resolve(this.user)
            } else {
                if (this.fbSubscription) this.fbSubscription.unsubscribe();
                this.fbSubscription = this.afAuth.authState.subscribe((userFirebase: firebase.User) => {
                    if (userFirebase) {
                        console.log('auth getUserFirebase() : ', userFirebase);
                        this.firestore.get('Users/' + userFirebase.uid).then(ref => {
                            console.log('auth get() : ', ref);
                            if (this.fsSubscription) this.fsSubscription.unsubscribe();
                            this.fsSubscription = ref.valueChanges().subscribe((userLocal: User) => {
                                this.user = userLocal;
                                console.log('auth getUserFirestore() : ', this.user);
                                resolve(this.user);
                            });
                        }).catch((e) => {
                            console.log('catch get fire user ', e)
                            reject(e);
                        });
                    } else {
                        reject(false);
                    }
                });
            }
        });
    }

    public logout(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.signOut().then((e) => {
                localStorage.removeItem('user');
                localStorage.removeItem('orders');
                localStorage.removeItem('lastBuy');
                //this.facebook.logout();
                //this.googlePlus.logout();
                this.events.publish('session-start', false);
                // console.log(e);
                this.user = null;
                // console.log('logout() true');
                resolve(true);
            }).catch((e) => {
                // console.log(e);
                // console.log('logout() false');
                reject(e);
            });
        });
    }

    // Reset password of the logged in user on Firebase.
    public resetPassword(email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.sendPasswordResetEmail(email).then((res) => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }

    public registerWithEmail(email: string, password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(res => {
                // console.log('1 - createUserWithEmailAndPassword res: ', res);
                resolve(res);
                // console.log('create registerWithEmail');
                this.sendEmailVerification().then(r => console.log('then sendEmailVerification', res)).catch(err => console.error('catch sendEmailVerification', err));
            }).catch(err => {
                reject(err);
            });
        });
    }

    public sendEmailVerification(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.afAuth.authState.subscribe(user => {
                user.sendEmailVerification().then((res) => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            });
        });
    }

    public loginWithGoogle(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const res = await this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(res => {
                const user = res.user;
                console.log("loginGoogle: ", user);
                resolve(res);
            }).catch((e) => {
                console.log("loginGoogle Error: ", JSON.stringify(e));
                reject(e);
            });
        });
    }

    public loginWithFacebook(): Promise<any> {
        console.log("facebook");
        return new Promise(async (resolve, reject) => {
            // Sign in using a popup.
            let provider = new firebase.auth.FacebookAuthProvider();
            provider.addScope('email');
            firebase.auth().signInWithPopup(provider).then((result) => {
                console.log("loginFacebook: ", result);
                // This gives you a Facebook Access Token.
                let token = result.credential.providerId;
                // The signed-in user info.
                let user = result.user;
                resolve(result);
            }).catch((e) => {
                console.error("loginFacebook Error: ", JSON.stringify(e));
                reject(e);
            });
        });
    }
}
