import {Component, OnInit, ViewChild} from '@angular/core';
import {addressCompany, Direction_User, User} from "../../../shared/classes/user";
import {Router} from "@angular/router";
import {AuthService} from "../../../shared/services/auth.service";
import {FirestoreService} from "../../../services/firestore.service";
import {ToastrService} from "ngx-toastr";
import {HttpService} from "../../../services/http.service";
import {ViewportScroller} from "@angular/common";
import {element} from "protractor";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    @ViewChild('form') targetElement: any;
    user = new User();
    addressCompany = new addressCompany();
    isLogged: boolean = false;
    showNewAddress: boolean = false;
    showBtnSave: boolean = true;
    address = new Direction_User();
    type: string;
    direction: string;
    zipCode: string;
    country: string;
    city: string;
    state: string;

    constructor(
        private router: Router,
        private auth: AuthService,
        private firestore: FirestoreService,
        private service: HttpService,
        private viewScroller: ViewportScroller,
        private toastrService: ToastrService,
    ) {
        this.auth.getUser2().then(async userLocal => {
            this.isLogged = true;
            console.log("userLocal: ", userLocal);
            this.user = userLocal;
            // console.log('addressCompany', this.user.addressCompany);
            this.addressCompany = this.user.addressCompany == undefined ? this.addressCompany : this.user.addressCompany;
            console.table(this.user);
        }).catch(e => {
            console.log('catch ', e)
            this.router.navigate(['/pages/login']);
        })
    }

    ngOnInit(): void {
        console.log('addressCompany', this.addressCompany);
        // let test = document.querySelector('.card-header') as HTMLElement | null;
        // console.log('test', test);
    }

    updateUser() {
        this.showBtnSave = false;
        console.log('info user', this.user);
        console.log('info address', this.address);

        if (this.address.state != '' && this.address.direction != '' && this.address.zipCode != '' && this.address.country != '' && this.address.city != '') {
            this.user.direction.push({
                direction: this.address.direction,
                city: this.address.city,
                country: this.address.country,
                is_default: false,
                lat_long: undefined,
                name_place: this.address.type,
                state: this.address.state,
                timestamp: undefined,
                type: this.address.type,
                zipCode: this.address.zipCode
            });
        }
        this.user.addressCompany = {...this.addressCompany};
        this.toastrService.info('Procesando información, por favor espere');
        console.log('send', {...this.user});
        this.service.updateUser({...this.user}, this.user.uid).subscribe( res => {
            console.log("update: ", res);
            this.toastrService.success('Información procesada correctamente');
        })
        /*this.service.updateUser({...this.user}, this.user.uid).then(res => {
            console.log("edit: ", res);
            this.toastrService.success('Información procesada correctamente');
            // window.location.reload();
        }).catch(err => {
            console.log("catch update: ", err);
            this.toastrService.error('Se ha producido un error al procesar la información, inténtelo de nuevo.');
        });*/
        this.showBtnSave = true;
    }

    changeStatus(index) {
        let status = this.user.direction[index].is_default;
        let txt = !status ? 'activated' : 'disabled'

        this.user.direction.forEach((element, i) => {
            if (!status && index != i) element.is_default = false;
        })

        this.user.direction[index].is_default = !status;
        this.toastrService.info('Address  ' + txt);
    }

    newAddress() {
        this.showNewAddress = !this.showNewAddress;
        // let height = this.targetElement.nativeElement.offsetHeight;
        setTimeout(() => {
            let top = this.targetElement.nativeElement.offsetHeight;
            this.viewScroller.scrollToPosition([0, top * 2]);
        }, 500)
    }
}
