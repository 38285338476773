<!--<app-breadcrumb [title]="infoSubcategory.nameSubcategory" [breadcrumb]="" [breadcrumbDynamic]="sendBreadcrumbDynamic"></app-breadcrumb>-->

<!-- section start -->
<section class="section-b-space pt-0 gym-product">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
                        <!-- side-bar colleps block stat -->
                        <div class="collection-filter-block">
                            <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                                <span class="filter-back">
                                    <i class="fa fa-angle-left" aria-hidden="true"></i> back
                                </span>
                            </div>
                            <!-- Category filter start -->
                            <app-subcategories [subcategories]="categories" [href]="true" [title]="'categoría'" ></app-subcategories>
                            <!-- Subcategory filter start -->
                            <app-subcategories [subcategories]="subcategories" [href]="true"></app-subcategories>
                            <!-- Clasificacion filter start -->
                            <app-subcategories *ngIf="classifications.length > 0" [title]="'Clasificaciones'" [subcategories]="classifications"></app-subcategories>
                        </div>
                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar colleps block stat -->
                        <div class="collection-filter-block">
                            <!-- brand filter start -->
                            <app-brands [products]="products" [brands]="brands" (brandsFilter)="updateFilter($event)"></app-brands>
                            <!-- price filter start here -->
                            <!--<app-price [min]="minPrice" [max]="maxPrice" (priceFilter)="priceFilter($event)"></app-price>
                            <app-diametro-interior [min]="minDI" [max]="maxDI" (diametroInterior)="diametroInteriorFilter($event)"></app-diametro-interior>
                            <app-diametro-exterior [min]="minDE" [max]="maxDE" (diametroExterior)="diametroExteriorFilter($event)"></app-diametro-exterior>
                            <app-largo [min]="minL" [max]="maxL" (largo)="largoFilter($event)"></app-largo>-->

                            <!-- price filter start here -->
                            <div *ngIf="maxPrice > 0" class="collection-collapse-block border-0" [class.open]="collapse">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">Precio</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="mt-2">
                                            <ng5-slider
                                                    [(value)]="minPrice"
                                                    [(highValue)]="maxPrice"
                                                    [options]="optionsPrice"
                                                    (userChangeEnd)="priceFilter($event)"
                                                    *ngIf="isBrowser">
                                            </ng5-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- price filter end here -->

                            <!-- diámetro exterior filter start here -->
                            <div *ngIf="maxDE > 0" class="collection-collapse-block border-0" [class.open]="collapse">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">Diámetro exterior</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="mt-2">
                                            <ng5-slider
                                                    [(value)]="minDE"
                                                    [(highValue)]="maxDE"
                                                    [options]="optionsDE"
                                                    (userChangeEnd)="diametroExteriorFilter($event)"
                                                    *ngIf="isBrowser">
                                            </ng5-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- diámetro exterior filter end here -->

                            <!-- diámetro interior filter start here -->
                            <div *ngIf="maxDI > 0" class="collection-collapse-block border-0" [class.open]="collapse">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">Diámetro interior</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="mt-2">
                                            <ng5-slider
                                                    [(value)]="minDI"
                                                    [(highValue)]="maxDI"
                                                    [options]="optionsDI"
                                                    (userChangeEnd)="diametroInteriorFilter($event)"
                                                    *ngIf="isBrowser">
                                            </ng5-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- diámetro interior filter end here -->

                            <!-- altura filter start here -->
                            <div *ngIf="maxL > 0" class="collection-collapse-block border-0" [class.open]="collapse">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">Altura</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="mt-2">
                                            <ng5-slider
                                                    [(value)]="minL"
                                                    [(highValue)]="maxL"
                                                    [options]="optionsL"
                                                    (userChangeEnd)="largoFilter($event)"
                                                    *ngIf="isBrowser">
                                            </ng5-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- altura exterior filter end here -->
                        </div>
                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar single product slider start -->
                        <app-product-box-vertical-slider [title]="'Nuevos productos'">
                        </app-product-box-vertical-slider>
                        <!-- side-bar single product slider end -->
                        <!-- side-bar banner start here -->
                        <div [hidden]="true" class="collection-sidebar-banner">
                            <a [routerLink]="['/shop/collection/left/sidebar']">
                                <img src="assets/images/collection-sidebar-banner.png" class="img-fluid" alt="banner">
                            </a>
                        </div>
                        <!-- side-bar banner end here -->
                    </div>
                </div>
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="top-banner-wrapper d-grid">
                                    <div class="top-banner-wrapper d-grid">
                                        <img [defaultImage]="loadGif" [lazyLoad]="infoSubcategory.bannerSubcategory" style="margin: auto" class="img-fluid " alt="">
                                        <div class="top-banner-content small-section">
                                            <h4 [textContent]="infoSubcategory.nameSubcategory"></h4>
                                            <h5 [textContent]="infoSubcategory.subcategory_description"></h5>
                                            <p [textContent]="infoSubcategory.subcategory_highlights_description"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="filter-main-btn" (click)="toggleMobileSidebar()">
                                            <span class="filter-btn btn btn-theme">
                                                <i class="fa fa-filter" aria-hidden="true"></i> Filter
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- classifications -->
                                <!--<ng-container *ngIf="classifications.length > 0">
                                    <div class="container category-button">
                                        <section class="py-2">
                                            <div class="row partition1 align-items-center">
                                                <ng-container *ngFor="let item of classifications">
                                                    <div class="col-lg-3 my-lg-1"><a [routerLink]="[item.url]" class="btn btn-outline btn-block">{{ item.name }}</a></div>
                                                </ng-container>
                                            </div>
                                        </section>
                                    </div>
                                </ng-container>-->
                                <ul class="product-filter-tags">
                                    <li class="me-1" *ngFor="let tag of tags">
                                        <a href="javascript:void(0)" class="filter_tag">
                                            {{tag}} <i class="ti-close" (click)="removeTag(tag)"></i>
                                        </a>
                                    </li>
                                    <li class="clear_filter" *ngIf="tags.length > 0">
                                        <a href="javascript:void(0)" class="clear_filter" (click)="removeAllTags()">
                                            Clear all
                                        </a>
                                    </li>
                                </ul>
                                <div class="collection-product-wrapper" id="products">
                                    <app-grid [products]="products" [paginate]="paginate" [layoutView]="layoutView" [sortBy]="sortBy" (setGrid)="updateGridLayout($event)" (setLayout)="updateLayoutView($event)" (sortedBy)="sortByFilter($event)">
                                    </app-grid>
                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row align-items-center">
                                            <div class="col-grid-box" [ngClass]="grid" *ngFor="let product of products">
                                                <div class="product-box">
                                                    <app-product-box-one
                                                            [product]="product"
                                                            [currency]="productService?.Currency"
                                                            [thumbnail]="false"
                                                            [cartModal]="true">
                                                    </app-product-box-one>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!products.length && showLoading">
                                                <img [src]="loadGif" class="img-fluid mb-4">
                                                <p>Cargando contenido</p>
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!products.length && !showLoading">
                                                <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                                                <h3>¡Lo sentimos! No se ha encontrado el producto que buscaba. </h3>
                                                <p>Por favor, compruebe si ha filtrado algo mal o intente buscar con otros filtros.</p>
                                                <a [routerLink]="['/products']" class="btn btn-solid">seguir comprando</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Infinite Scroll -->
                                    <!--<div infiniteScroll
                                         [infiniteScrollDistance]="2"
                                         [infiniteScrollThrottle]="1000"
                                         (scrolled)="onScroll()"
                                         *ngIf="products.length">
                                    </div>-->
                                    <div class="product-infinitescroll">
                                        <div class="theme-paggination-block">
                                            <div class="container-fluid p-0">
                                                <div class="row">
                                                    <div class="text-center col-sm-12">
                                                        <div class="infinite-scrolling mt-3 mb-3 d-inline-block">
                                                            <img src="assets/images/loader.gif" *ngIf="loading">
                                                            <div *ngIf="!loading && !finished" (click)="onScroll()" class="loading-more active my-3 cursor-pointer">Mostrar más</div>
                                                            <div class="loading-more active" *ngIf="finished && products.length">No More Products</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->
