<!-- header start -->
<header class="sticky" style="background-color: {{ color }}" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu border-section border-top-0">
                    <div class="menu-left">
                        <app-left-menu></app-left-menu>
                    </div>
                    <div class="brand-logo layout2-logo p-0">
                        <a [routerLink]="['/']">
                            <img [src]="themeLogo" class="img-fluid" alt="logo">
                        </a>
                    </div>
                    <div>
                        <form class="form_search" role="form">
                            <input (keyup.enter)="search()" id="query search-autocomplete" name="query" [(ngModel)]="contentSearch" placeholder="Búsqueda de productos" class="nav-search nav-search-field" aria-expanded="true">
                            <button (click)="search()" type="button" name="nav-submit-button" class="btn-search">
                                <i class="ti-search"></i>
                            </button>
                        </form>
                    </div>
                    <div class="menu-right pull-right align-items-center">
                        <div>
                            <app-settings></app-settings>
                        </div>
                        <div class="mx-4" [hidden]="!boxMenu">
                            <app-menu></app-menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row ">
            <div class="col-lg-12">
                <div class="main-nav-center">
                    <app-menu></app-menu>
                </div>
            </div>
            <div class="col">
                <form class="form_search form-mobile" role="form">
                    <input (keyup.enter)="search()" id="query search-autocomplete2" name="query" [(ngModel)]="contentSearch" placeholder="Búsqueda de productos" class="nav-search nav-search-field" aria-expanded="true">
                    <button (click)="search()" type="button" name="nav-submit-button" class="btn-search">
                        <i class="ti-search"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
</header>
<!-- header end -->
