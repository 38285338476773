<!--<app-breadcrumb [title]="'Checkout'" [breadcrumb]="'Checkout'"></app-breadcrumb>-->
<section class="section-b-space">
    <div class="container">
        <div class="checkout-page">
            <div class="checkout-form">
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-sm-12 col-xs-12">
                            <div class="checkout-title">
                                <h3>Datos de facturación</h3>
                            </div>
                            <div class="row check-out">
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">Nombre</div>
                                    <p [textContent]="user.firstName"></p>
                                    <!--                                    <input [readOnly]="true" type="text" name="firstname" [value]="user.firstName == '' ? 'default' : user.firstName" placeholder="" autocomplete="off">-->
                                    <!--<div *ngIf="checkoutForm.controls.firstname.touched && checkoutForm.controls.firstname.errors?.required" class="text text-danger">
                                        First Name is required.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.firstname.touched && checkoutForm.controls.firstname.errors?.pattern" class="text text-danger">
                                        First Name must be an alphabates.
                                    </div>-->
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">Apellido</div>
                                    <p [textContent]="user.lastName"></p>
                                    <!--                                    <input [readOnly]="true" type="text" name="lastname" [value]="user.lastName == '' ? 'default' : user.lastName" placeholder="" autocomplete="off">-->
                                    <!--<div *ngIf="checkoutForm.controls.lastname.touched && checkoutForm.controls.lastname.errors?.required" class="text text-danger">
                                        Last Name is required.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.lastname.touched && checkoutForm.controls.lastname.errors?.pattern" class="text text-danger">
                                        Last Name must be an alphabates.
                                    </div>-->
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">Teléfono</div>
                                    <p [textContent]="user.phone"></p>
                                    <!--                                    <input [readOnly]="true" type="text" name="phone" placeholder="" autocomplete="off">-->
                                    <!--<div *ngIf="checkoutForm.controls.phone.touched && checkoutForm.controls.phone.errors?.required" class="text text-danger">
                                        Phone No is required.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.phone.touched && checkoutForm.controls.phone.errors?.pattern" class="text text-danger">
                                        Phone No is must be number.
                                    </div>-->
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">Dirección de correo electrónico</div>
                                    <p [textContent]="user.email"></p>
                                    <!--                                    <input [readOnly]="true" type="text" name="email" [value]="user.email == '' ? 'default' : user.email" placeholder="" autocomplete="off">-->
                                    <!--<div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.required" class="text text-danger">
                                        Email is required
                                    </div>
                                    <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.email" class="text text-danger">
                                        Invalid Email
                                    </div>-->
                                </div>
                                <!--<div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">Country</div>
                                    <select name="country" [formControl]="checkoutForm.controls['country']">
                                        <option [value]="user.country == '' ? 'default' : user.country" selected>Select Country</option>
                                        <option value="India">India</option>
                                        <option value="USA">USA</option>
                                        <option value="Australia">Australia</option>
                                    </select>
                                    <div *ngIf="checkoutForm.controls.country.touched && checkoutForm.controls.country.errors?.required" class="text text-danger">
                                        Country is required
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">Address</div>
                                    <input type="text" name="address" [formControl]="checkoutForm.controls['address']" [value]="user.address == '' ? 'default' : user.address" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.address.touched && checkoutForm.controls.address.errors?.required" class="text text-danger">
                                        Address is required
                                    </div>
                                    <div *ngIf="checkoutForm.controls.address.touched && checkoutForm.controls.address.errors?.maxlength" class="text text-danger">
                                        Maximum 50 character
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">Town/City</div>
                                    <input type="text" name="town" [formControl]="checkoutForm.controls['town']" [value]="user.municipality == '' ? 'default' : user.municipality" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.town.touched && checkoutForm.controls.town.errors?.required" class="text text-danger">
                                        City is required
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div class="field-label">State</div>
                                    <input type="text" name="state" [formControl]="checkoutForm.controls['state']" [value]="user.municipality == '' ? 'default' : user.municipality" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.state.touched && checkoutForm.controls.state.errors?.required" class="text text-danger">
                                        State is required
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div class="field-label">Postal Code</div>
                                    <input type="text" name="postalcode" [formControl]="checkoutForm.controls['postalcode']" [value]="user.postalcode == '' ? 'default' : user.postalcode" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.postalcode.touched && checkoutForm.controls.postalcode.errors?.required" class="text text-danger">
                                        Postalcode is required
                                    </div>
                                </div>-->
                            </div>
                            <ng-container *ngIf="invoicing == 'Crédito Fiscal'">
                                <div class="checkout-title">
                                    <h3>Dirección de envio principal</h3>
                                </div>
                                <p>Estimado usuario, verifique que la información a utilizar para generar su crédito fiscal este correcta.
                                    Si desea actualizar la información, por favor dar clic en el siguiente enlace <a [routerLink]="'/pages/profile'">Editar</a> Caso contrario, continuar con el proceso de compra.</p>
                                <div class="row check-out">
                                    <ng-container *ngFor="let item of user.direction">
                                        <ng-container *ngIf="item.is_default">
                                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                                <div class="field-label">Dirección</div>
                                                <p [textContent]="item.direction"></p>
                                            </div>
                                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                                <div class="field-label">Pais</div>
                                                <p [textContent]="item.country"></p>
                                            </div>
                                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                                <div class="field-label">Ciudad</div>
                                                <p [textContent]="item.city"></p>
                                            </div>
                                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                                <div class="field-label">Estado/Municipio</div>
                                                <p [textContent]="item.state"></p>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div [hidden]="true" class="row ">
                                <div class="col-12">
                                    <div class="stripe-section">
                                        <h5>stripe js</h5>
                                        <div class="content">
                                            <h5>dummy test</h5>
                                            <table>
                                                <tr>
                                                    <td>cart number</td>
                                                    <td>4242424242424242</td>
                                                </tr>
                                                <tr>
                                                    <td>mm/yy</td>
                                                    <td>2/2020</td>
                                                </tr>
                                                <tr>
                                                    <td>cvc</td>
                                                    <td>2222</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div [hidden]="true" class="row margin-cls">
                                <div class="col-12">
                                    <div class="stripe-section">
                                        <h5>paypal</h5>
                                        <div class="content">
                                            <h5>dummy test</h5>
                                            <table>
                                                <tr>
                                                    <td>cart number</td>
                                                    <td>4152521541244</td>
                                                </tr>
                                                <tr>
                                                    <td>mm/yy</td>
                                                    <td>11/18</td>
                                                </tr>
                                                <tr>
                                                    <td>cvc</td>
                                                    <td>521</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 col-xs-12">
                            <div class="checkout-details">
                                <div class="order-box">
                                    <div class="title-box">
                                        <div>Producto <span> Total</span></div>
                                    </div>
                                    <ul class="qty" *ngIf='products.length'>
                                        <li *ngFor="let product of products">
                                            {{ product.title }} × {{ product.quantity }}
                                            <span>
                                                {{ (product.price | discount:product) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                                            </span>
                                        </li>
                                    </ul>
                                    <ul class="qty" *ngIf='!products.length'>
                                        <li class="empty-checkout"> No hay productos en el carrito</li>
                                    </ul>
                                    <ul class="sub-total">
                                        <li>
                                            Subtotal <span class="count">{{ getTotal | async | currency:productService?.Currency.currency:'symbol'}}</span>
                                        </li>
                                        <li *ngIf='products.length'>
                                            <div class="row">
                                                <div class="col-lg-6 select_input my-1">
                                                    <label for="country">Método de envio</label>
                                                    <select (change)="metodoEnvio($event.target.value)" id="country" name="country" [(ngModel)]="shipping" class="form-control form-control-sm" size="1">
                                                        <option value="">Seleccione metodo envio</option>
                                                        <ng-container *ngFor="let item of methodShipping">
                                                            <option *ngIf="item.active" [value]="item.name">{{item.name}}</option>
                                                        </ng-container>
                                                    </select>
                                                </div>
                                                <div class="col-lg-6 select_input my-1">
                                                    <label for="country">Documento a generar</label>
                                                    <select (change)="documentoGenerar($event.target.value)" id="invoicing" name="invoicing" [(ngModel)]="invoicing" class="form-control form-control-sm" size="1">
                                                        <option value="">Seleccione documento a generar</option>
                                                        <ng-container *ngFor="let item of methodInvoicing">
                                                            <option *ngIf="item.active" [value]="item.name">{{item.name}}</option>
                                                        </ng-container>
                                                    </select>
                                                </div>
                                                <div *ngIf="shipping == 'Retiro en sucursal'" class="col-lg-6 select_input my-1">
                                                    <label for="country">Sucursal Retiro</label>
                                                    <select (change)="sucursalRetiro($event.target.value)" id="sucursal" name="sucursal" [(ngModel)]="sucursal" class="form-control form-control-sm" size="1">
                                                        <option value="">Seleccione sucursal</option>
                                                        <ng-container *ngFor="let item of sucursales; let i = index">
                                                            <option [value]="item.company_dba_name">{{item.company_dba_name}}</option>
                                                        </ng-container>
                                                    </select>
                                                </div>
                                                <div class="col-lg-6 select_input my-1">
                                                    <label for="country">Método Pago</label>
                                                    <select (change)="paymentMethod($event.target.value)" id="pago" name="pago" [(ngModel)]="pago" class="form-control form-control-sm" size="1">
                                                        <option value="">Seleccione metodo pago</option>
                                                        <ng-container *ngFor="let item of metodoPago; let i = index">
                                                            <option *ngIf="item.active" [value]="item.name">{{item.name}}</option>
                                                        </ng-container>
                                                    </select>
                                                    <label>
                                                        <span class="small-text" [innerHtml]="txtMessage"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="total">
                                        <li>Total <span class="count">{{ getTotal | async | currency:productService?.Currency.currency:'symbol'}}</span></li>
                                    </ul>
                                </div>

                                <div class="payment-box" *ngIf='products.length'>
                                    <div class="upper-box">
                                        <div class="payment-options">
                                            <ul>
                                                <li [hidden]="true">
                                                    <div class="radio-option">
                                                        <input type="radio" [(ngModel)]="payment" [value]="'Stripe'" name="payment-group" id="payment-2">
                                                        <label for="payment-2">Stripe
                                                            <span class="small-text">Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</span></label>
                                                    </div>
                                                </li>
                                                <!--<li>
                                                    <div class="radio-option paypal">
                                                        <input type="radio" [(ngModel)]="payment" [value]="'Paypal'" name="payment-group" id="payment-3">
                                                        <label for="payment-3">PayPal<span class="image"><img src="assets/images/paypal.png" alt=""/></span></label>
                                                    </div>
                                                </li>-->
                                                <li [hidden]="true">
                                                    <div class="radio-option wompi">
                                                        <input type="radio" [(ngModel)]="payment" [value]="'Wompi'" name="payment-group" id="payment-3">
                                                        <label for="payment-2">Wompi
                                                            <span class="small-text">Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</span>
                                                        </label>
                                                    </div>
                                                </li>
                                                <li *ngIf="user.phone == null && shipping != '' && invoicing != '' && sucursal!= '' && pago!= ''">
                                                    <label for="payment-2">
                                                        <span class="small-text">
                                                            Estimado usuario, con el metodo de pago seleccionado, es necesario que nos proporcione un numero de contacto para poder comunicarnos con usted para poder dar
                                                            seguimiento al estado de su pedido.
                                                            <br>
                                                            Para actualizar la informacion de su contacto, puede dar clic en el siguiente enlace.
                                                             <a [routerLink]="'/pages/profile'">Actualizar</a>
                                                        </span>
                                                    </label>
                                                </li>
                                                <li *ngIf="user.phone != null && !isWompi && shipping != '' && invoicing != '' && sucursal!= '' && pago!= ''">
                                                    <label for="payment-2">
                                                        <span class="small-text">
                                                            Estimado usuario, si en 24h su pedido no está pagado por transferencia bancaria, el pedido se cancelará automáticamente y los productos estarán disponibles para su compra
                                                        </span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="text-end">
                                        <button [hidden]="true" type="submit" class="btn-solid btn" [disabled]="!checkoutForm.valid" (click)="stripeCheckout()" *ngIf="payment == 'Stripe'">
                                            Place Order
                                        </button>
                                        <ng-container *ngIf="!verify">
                                            <button *ngIf="isWompi && shipping != '' && invoicing != '' && sucursal!= '' && pago!= ''" [disabled]="user.email == '' || user.phone == null" type="submit" class="btn-solid btn mx-2" (click)="pagoWompi(content)">
                                                Pagar
                                            </button>
                                        </ng-container>
                                        <button *ngIf="!isWompi && shipping != '' && invoicing != '' && sucursal!= '' && pago!= ''" [disabled]="user.email == '' || user.phone == null" type="submit" class="btn-solid btn mx-2"
                                                (click)="confirmarPedido()">
                                            Realizar Pedido
                                        </button>
                                        <!--<button *ngIf="verify" type="submit" class="btn-solid btn mx-2" (click)="confirmParymentWompi()">
                                            Confirmar pago
                                        </button>
                                        <button *ngIf="verify" type="submit" class="btn-solid btn mx-2" (click)="openVerticallyCentered(content)">
                                            Realizar pago.
                                        </button>-->
                                        <!-- <ngx-paypal [config]="payPalConfig" *ngIf="payment == 'Paypal'"></ngx-paypal> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<!--modal popup start-->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Pasarela de pago Wompi</h4>
        <!--        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('se arrepintió')"></button>-->
    </div>
    <div class="modal-body">
        <iframe class="w-100 h-100" [src]="urlPayment"></iframe>
    </div>
    <div class="modal-footer">
        <button *ngIf="verify" type="submit" class="btn-solid btn mx-2" (click)="cancelPaymentWompi()">
            Cancelar pago
        </button>
        <button *ngIf="verify" type="submit" class="btn-solid btn mx-2" (click)="confirmParymentWompi()">
            Confirmar pago
        </button>
    </div>
</ng-template>
<!--modal popup end-->

<div hidden>
    <table id="table-invoice" cellpadding="0" cellspacing="0" class="esdev-mso-table" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;width:560px">
        <tr *ngFor="let item of products" style="border-top: 2px solid #e9e9e9;">
            <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                <table cellpadding="0" cellspacing="0" class="es-left" align="left" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                    <tr>
                        <td class="es-m-p0r" align="center" style="padding:0;Margin:0;width:265px">
                            <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tr>
                                    <td align="left" style="padding:0;Margin:0">
                                        <p
                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px">
                                            <b>{{item.title}}</b>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
            <td style="padding:0;Margin:0;width:10px"></td>
            <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                <table cellpadding="0" cellspacing="0" class="es-left" align="left" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                    <tr>
                        <td align="center" style="padding:0;Margin:0;width:70px">
                            <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tr>
                                    <td align="center" style="padding:0;Margin:0">
                                        <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px">
                                            <b>{{item.quantity}}</b>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
            <td style="padding:0;Margin:0;width:20px"></td>
            <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                <table cellpadding="0" cellspacing="0" class="es-left" align="left" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                    <tr>
                        <td align="left" style="padding:0;Margin:0;width:80px">
                            <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tr>
                                    <td align="center" style="padding:0;Margin:0">
                                        <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px">
                                            <b>{{ (item.price | discount:item) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}</b>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
            <td style="padding:0;Margin:0;width:20px"></td>
            <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                <table cellpadding="0" cellspacing="0" class="es-right" align="right"
                       style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
                    <tr>
                        <td align="left" style="padding:0;Margin:0;width:85px">
                            <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tr>
                                    <td align="right" style="padding:0;Margin:0">
                                        <p
                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px">
                                            <b>{{ (item.price | discount:item) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}</b>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</div>



