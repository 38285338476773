import {Component, OnInit, Input} from '@angular/core';
import {NewProductSlider} from '../../../data/slider';
import {Product} from '../../../classes/product';
import {ProductService} from '../../../services/product.service';
import {HttpService} from "../../../../services/http.service";

@Component({
    selector: 'app-product-box-vertical-slider',
    templateUrl: './product-box-vertical-slider.component.html',
    styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

    @Input() title: string = 'New Product'; // Default
    @Input() type: string = ''; // Default Fashion
    data = {
        "total_docs_by_collection": 8e3,
        "num_page": 1,
        "limit": 14,
        "offset": 14, // tiene que se el mismo que el limit
        "order_by": 'product_createday',
        "order": 'desc',
    };
    public products : Product[] = [];

    public NewProductSliderConfig: any = NewProductSlider;

    constructor(
        public productService: ProductService,
        private service: HttpService,
    ) {
        this.service.getProductsByOrder(this.data).subscribe( res => {
            console.log('new productos', res)
            this.products = [];
            this.products = this.productService.listProducts(res);
            console.log('new products', this.products);
        });
    }

    ngOnInit(): void {
        if (this.products.length < 6) this.addMoreProducts();
    }

    addMoreProducts() {
        let data = [];
        this.data.num_page++;
        this.service.getProductsByOrder(this.data).subscribe(res => {
            data = this.productService.listProducts(res);
            console.log('more new products');
        });
        this.products = this.products.concat(data);
    }
}
