import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../shared/services/auth.service";
import {addressCompany, User} from "../../../shared/classes/user";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {HttpService} from "../../../services/http.service";
import {ProductService} from "../../../shared/services/product.service";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    public openDashboard: boolean = false;
    user = new User();
    isLogged: boolean = false;
    public contents = [
        {
            name: 'Información de la cuenta',
            active: true,
        },
        {
            name: 'Mis pedidos',
            active: false,
        },
        {
            name: 'Datos de la empresa',
            active: false,
        },
    ]
    orders: any [] = [];
    nit: string = '';
    nrc: string = '';
    phone: number;
    address: string = '';
    department: string = '';
    municipality: string = '';
    socialReason: string = '';
    nameCommercial: string = '';

    constructor(
        private auth: AuthService,
        private router: Router,
        private toastrService: ToastrService,
        private service: HttpService,
        public productService: ProductService,
    ) {

    }

    ngOnInit(): void {
        this.auth.getUser2().then( userLocal => {
            this.isLogged = true;
            this.user = userLocal;
            this.nit = userLocal.addressCompany.nit;
            this.nrc = userLocal.addressCompany.nrc;
            this.phone = userLocal.addressCompany.phone;
            this.address = userLocal.addressCompany.address;
            this.department = userLocal.addressCompany.department;
            this.municipality = userLocal.addressCompany.municipality;
            this.socialReason = userLocal.addressCompany.socialReason;
            this.nameCommercial = userLocal.addressCompany.nameCommercial;
            this.getOrders();
        }).catch(e => {
            console.log('catch ', e)
            // this.router.navigate(['/pages/login']);
        })
    }

    ngAfterViewInit(): void {

    }


    ToggleDashboard() {
        this.openDashboard = !this.openDashboard;
    }

    showContent(index) {
        this.contents.forEach((element, i) => element.active = i == index)
    }

    signOut() {
        this.toastrService.info('logging out, please wait');
        this.auth.logout().then((e) => {
            this.isLogged = false;
            window.location.href = '/';
        }).catch((e) => {
            console.log('catch signOut', e);
        });
    }

    getOrders() {
        this.service.getOrdersByUser(this.user.uid, 1e3).subscribe(res => {
            console.log('response orders by B', res)
            this.orders = res.sort((a, b) => {
                if (  a.order_createday._nanoseconds < b.order_createday._nanoseconds) {
                    return -1;
                } else if (a.order_createday._nanoseconds > b.order_createday._nanoseconds) {
                    return 1;
                }
                return 0;
            })
        });
    }
}
