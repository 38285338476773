import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
      private afs: AngularFirestore
  ) { }

  public getOneWatcher<T>(collection: string, uid: string): Observable<any> {
    return this.afs.doc<T>(`${collection}/${uid}`).valueChanges();
  }

  public get(path: string): Promise<AngularFirestoreDocument<{}>> {
    return new Promise(resolve => {
      resolve(this.afs.doc(path));
    });
  }

  public update<T>(collection: string, uid: string, document): Promise<any> {
    return this.afs.doc<T>(`${collection}/${uid}`).update(document);
  }

  public save<T>(collection: string, document): Promise<any> {
    document.uid = document.uid != null && document.uid != '' && document.uid != undefined ? document.uid : this.afs.createId();
    return this.afs.doc<T>(`${collection}/${document.uid}`).set(document);
  }

  public getWhere<T>(collection: string, key: string, value: any): Observable<any> {
    console.log('value send getWhere', value);
    return this.afs.collection<T>(collection, ref => ref.where(key, '==', value)).valueChanges();
  }

  public getOne(collection: string, uid: string) {
    return this.afs.doc(`${collection}/${uid}`).valueChanges();
  }

  public search(collectionName, objectSearch) {
    console.log(objectSearch);

    /*
      After applying these query you may face this error:
      "ERROR FirebaseError: The query requires an index. You can create it here: URL"
      You will get above error with an URL - Click over that URL - Login in Firebase
      and this will prompt to Create an Index which is required in Firebase
      to apply queries to Database Collection.
    */
    return this.afs.collection(collectionName, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      // for (const prop in objectSearch) { query = query.where(`${prop}`, '==', `${objectSearch[prop]}`); }
      if (objectSearch.phone) {
        console.log('objectSearch.phone ' + objectSearch.phone);
        query = query.where('phone', '==', objectSearch.phone);
      }
      if (objectSearch.locations_uid) {
        console.log('objectSearch.locations_uid ' + objectSearch.locations_uid);
        query = query.where('locations_uid', '==', objectSearch.locations_uid);
      }
      if (objectSearch.commerce_uid && objectSearch.commerce_uid != '' && objectSearch.commerce_uid != undefined) {
        query = query.where('commerce_uid', '==', objectSearch.commerce_uid);
      }
      if (objectSearch.orders_status_uid && objectSearch.orders_status_uid != '' && objectSearch.orders_status_uid != undefined) {
        query = query.where('orders_status_uid', '==', objectSearch.orders_status_uid);
      }
      if (objectSearch.manager_user_uid && objectSearch.manager_user_uid != '' && objectSearch.manager_user_uid != undefined) {
        query = query.where('manager_user_uid', '==', objectSearch.manager_user_uid);
      }
      if (objectSearch.user_uid && objectSearch.user_uid != '' && objectSearch.user_uid != undefined) {
        query = query.where('user_uid', '==', objectSearch.user_uid);
      }
      if (objectSearch.category_uid) {
        query = query.where('category_uid', '==', objectSearch.category_uid);
      }
      if (objectSearch.joinDate) {
        objectSearch.joinDateTimestamp = new Date(objectSearch.joinDate);
        query = query.where('joinDateTimestamp', '==', objectSearch.joinDateTimestamp);
      }
      if (objectSearch.name) {
        query = query.where('name', '>=', objectSearch.name);
        query = query.where('name', '<=', objectSearch.name + '\uf8ff');
      }
      if (objectSearch.limit) {
        query = query.limit(objectSearch.limit);
      }
      if (objectSearch.orderByName) {
        query = query.orderBy(objectSearch.orderByName, objectSearch.orderByDir);
      }

      return query;
    }).snapshotChanges();
  }

  public getAll<T>(collection: string): Observable<any> {
    return this.afs.collection<T>(collection).valueChanges();
  }
}
