import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductService} from "../../shared/services/product.service";
import {Product} from "../../shared/classes/product";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../shared/services/auth.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

    public  products: Product[] = [];
    private orderId: number;


    constructor(
        public productService: ProductService,
        private toastrService: ToastrService,
        private router: Router,
        private auth: AuthService,
    ) {
        this.productService.cartItems.subscribe(response => this.products = response);
    }

    ngOnInit(): void {
        this.productService.updateCart();
    }

    public get getTotal(): Observable<number> {
        return this.productService.cartTotalAmount();
    }

    // Increament
    increment(product, qty = 1) {
        this.productService.updateCartQuantity(product, qty);
    }

    // Decrement
    decrement(product, qty = -1) {
        this.productService.updateCartQuantity(product, qty);
    }

    public removeItem(product: any) {
        this.productService.removeCartItem(product);
    }

    verify() {
        this.auth.getUser2().then(async userLocal => {
            this.router.navigate(['/shop/checkout']);
        }).catch(e => {
            this.router.navigate(['/pages/login']).then( () => this.toastrService.info('Estimado usuario, es necesario iniciar sesión para continuar con el proceso de compra.') );
        })
    }
}
