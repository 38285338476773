<!--<app-breadcrumb [title]="product?.title" [breadcrumb]="" [breadcrumbDynamic]="sendBreadcrumbDynamic"></app-breadcrumb>-->
<!-- section start -->
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-3">
                    <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
                        <div class="collection-filter-block">
                            <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                  <span class="filter-back">
                    <i class="fa fa-angle-left" aria-hidden="true"></i> back
                  </span>
                            </div>
                            <!-- Category filter start -->
                            <app-subcategories *ngIf="categories.length > 0" [subcategories]="categories" [href]="true" [title]="'categoría'"></app-subcategories>
                            <!-- Subcategory filter start -->
                            <app-subcategories *ngIf="subcategories.length > 0" [subcategories]="subcategories" [href]="true"></app-subcategories>
                            <!-- Clasificacion filter start -->
                            <app-subcategories *ngIf="classifications.length > 0" [title]="'Clasificaciones'" [subcategories]="classifications"></app-subcategories>
                        </div>
                        <!--<div class="collection-filter-block">
                            <app-services></app-services>
                        </div>-->
                        <app-product-box-vertical-slider
                                [title]="'nuevos productos'"
                                [type]="product?.type">
                        </app-product-box-vertical-slider>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 col-xs-12">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="filter-main-btn mb-2">
                                  <span class="filter-btn" (click)="toggleMobileSidebar()">
                                    <i class="fa fa-bars" aria-hidden="true"></i> sidebar
                                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <ng-container *ngIf="findProduct">
                                <div class="col-lg-6">
                                    <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar class="product-slick">
                                        <ng-container *ngFor="let image of product?.images; index as i">
                                            <ng-template carouselSlide [id]="i">
                                                <div>
                                                    <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                         [lazyLoad]="image.src"
                                                         [alt]="image.alt" class="img-fluid">
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                    <div class="col-12 p-0" *ngIf="product.images?.length > 1">
                                        <div class="slider-nav">
                                            <owl-carousel-o [options]="ProductDetailsThumbConfig" class="product-slick">
                                                <ng-container *ngFor="let image of product?.images; index as i">
                                                    <ng-template carouselSlide [id]="i">
                                                        <div class="owl-thumb" [class.active]="i == activeSlide">
                                                            <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                                 [lazyLoad]="image.src"
                                                                 [alt]="image.alt" class="img-fluid"
                                                                 (click)="owlCar.to(activeSlide = i.toString())">
                                                        </div>
                                                    </ng-template>
                                                </ng-container>
                                            </owl-carousel-o>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 rtl-text">
                                    <div class="product-right">
                                        <h2>{{ product?.title }}</h2>
                                        <h4 *ngIf="product?.discount && product?.product_promote">
                                            <del>{{ product?.price * productService.Currency?.price | currency: productService.Currency?.currency:'symbol' }}</del>
                                            <span>{{ product?.discount }}% off</span>
                                        </h4>
                                        <h3>
                                            {{ (product?.price | discount:product) * productService.Currency?.price | currency: productService.Currency?.currency:'symbol' }}
                                        </h3>
                                        <app-stock-inventory [stock]="product?.stock"></app-stock-inventory>
                                        <div class="product-description border-product">
                                            <h5 class="avalibility" *ngIf="counter <= product.stock"><span>En stock</span></h5>
                                            <h5 class="avalibility" *ngIf="counter > product.stock"><span>Agotado</span></h5>
                                            <h6 class="product-title">

                                            </h6>
                                            <div class="qty-box">
                                                <div class="input-group">
                                                <span class="input-group-prepend">
                                                  <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                                    <i class="ti-angle-left"></i>
                                                  </button>
                                                </span>
                                                    <input type="text" name="quantity" class="form-control input-number" [value]="counter" disabled/>
                                                    <span class="input-group-prepend">
                                                  <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                                    <i class="ti-angle-right"></i>
                                                  </button>
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-buttons">
                                            <a class="btn btn-solid" [class.disabled]="counter > product.stock || !findProduct" (click)="addToCart(product)">añadir al carrito</a>
                                            <a class="btn btn-solid" [class.disabled]="counter > product.stock || !findProduct" (click)="buyNow(product)">Comprar ahora</a>
                                        </div>
                                        <div class="border-product">
                                            <h6 class="product-title">Detalles del producto</h6>
                                            <p [innerHtml]="product.description"></p>
                                            <p [innerHtml]="product.short_description || product.long_product_description"></p>
                                        </div>
                                    </div>
                                </div>
                                <section class="tab-product m-0">
                                    <div class="row">
                                        <div class="col-sm-12 col-lg-12">
                                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                                <li [ngbNavItem]="1">
                                                    <a ngbNavLink>Especificaciones</a>
                                                    <ng-template ngbNavContent>
                                                        <div [innerHtml]="product.especifications || product.specifications"></div>
                                                    </ng-template>
                                                </li>
                                                <!--<li [ngbNavItem]="3">
                                                    <a ngbNavLink>Write Review</a>
                                                    <ng-template ngbNavContent>
                                                        <form class="theme-form">
                                                            <div class="row">
                                                                <div class="col-md-12 ">
                                                                    <div class="media rating-sec">
                                                                        <label>Rating</label>
                                                                        <div class="media-body ms-3">
                                                                            <div class="rating three-star">
                                                                                <i class="fa fa-star"></i>
                                                                                <i class="fa fa-star"></i>
                                                                                <i class="fa fa-star"></i>
                                                                                <i class="fa fa-star"></i>
                                                                                <i class="fa fa-star"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="name">Name</label>
                                                                    <input type="text" class="form-control" id="name" placeholder="Enter Your name" required>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="email">Email</label>
                                                                    <input type="text" class="form-control" id="email" placeholder="Email" required>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <label for="review">Review Title</label>
                                                                    <input type="text" class="form-control" id="review" placeholder="Enter your Review Subjects" required>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <label for="review">Review Title</label>
                                                                    <textarea class="form-control" placeholder="Wrire Your Testimonial Here" id="exampleFormControlTextarea1" rows="6"></textarea>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <button class="btn btn-solid" type="submit">Submit YOur Review</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </ng-template>
                                                </li>-->
                                            </ul>
                                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                                        </div>
                                    </div>
                                </section>
                            </ng-container>
                            <ng-container *ngIf="showError">
                                <!-- section start -->
                                <section class="p-0">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="error-section">
                                                    <h1>Opps</h1>
                                                    <h2>Ha ocurrido un error a la hora de buscar el producto.</h2>
                                                    <a [routerLink]="'/'" class="btn btn-solid">back to home</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <!-- Section ends -->
                            </ng-container>
                            <!--<div class="col-lg-12">
                                <app-related-product [urlClasificacion]="urlClassification" [urlSubcategory]="urlSubcategory" [type]="product?.type"></app-related-product>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-size-modal #sizeChart [product]="product"></app-size-modal>
<!-- Section ends -->
