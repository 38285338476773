<!-- breadcrumb start -->
<app-breadcrumb [title]="'404 page'" [breadcrumb]="'404 page'" *ngIf="!orderDetails"></app-breadcrumb>
<!-- breadcrumb End -->

<ng-container *ngIf="this.isLogged">
    <!-- section start -->
    <section class="p-0" *ngIf="!orderDetails">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="error-section">
                        <h1>404</h1>
                        <h2>página no encontrada</h2>
                        <a [routerLink]="'/home'" class="btn btn-solid">home</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Section ends -->

    <!-- thank-you section start -->
    <section class="section-b-space light-layout" *ngIf="orderDetails">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="success-text">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        <h2>gracias</h2>
                        <span [innerHtml]="txtSuccess.replaceAll('$order_uid', orderDetails.orderId)"></span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Section ends -->

    <!-- order-detail section start -->
    <section class="section-b-space" *ngIf="orderDetails.items.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="product-order">
                        <h3>datos de su pedido</h3>
                        <div class="row product-order-detail" *ngFor="let product of orderDetails.items">
                            <div class="col-3">
                                <img [src]="product.image" [alt]="product.name" class="img-fluid">
                            </div>
                            <div class="col-3 order_detail">
                                <div>
                                    <h4>nombre del producto</h4>
                                    <h5>{{ product.title || product.name}}</h5>
                                </div>
                            </div>
                            <div class="col-3 order_detail">
                                <div>
                                    <h4>cantidad</h4>
                                    <h5>{{ product.quantity }}</h5>
                                </div>
                            </div>
                            <div class="col-3 order_detail">
                                <div>
                                    <h4>precio</h4>
                                    <h5>{{ product?.price * productService?.Currency?.price | discount:product | currency:productService?.Currency.currency:'symbol' }}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="total-sec">
                            <ul>
                                <li>Subtotal <span>{{ orderDetails.totalAmount || orderDetails.total | currency:productService?.Currency.currency:'symbol' }}</span></li>
                                <li>Envío <span>{{ orderDetails.shipping_cost || 0.00 | currency:productService?.Currency.currency:'symbol'}}</span></li>
                                <li>Impuestos <span>{{ orderDetails.tax || 0.00 | currency:productService?.Currency.currency:'symbol' }}</span></li>
                            </ul>
                        </div>
                        <div class="final-total">
                            <h3>total <span>{{ orderDetails.totalAmount || orderDetails.total | currency:productService?.Currency.currency:'symbol' }}</span></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row order-success-sec">
                        <div class="col-sm-6">
                            <h4>Detalle de orden</h4>
                            <ul class="order-detail">
                                <li><b>Orden ID:</b> {{ orderDetails.orderId }}</li>
                                <li><b>Fecha Orden:</b> {{ orderDetails.created_at_formatted }}</li>
                                <li><b>Total Orden:</b> {{ orderDetails.totalAmount | currency:productService?.Currency.currency:'symbol' }}</li>
                            </ul>
                        </div>
                        <!--<div [hidden]="true" class="col-sm-6">
                            <h4>shipping address</h4>
                            <ul class="order-detail">
                                <li>{{ orderDetails.shippingDetails.address }}</li>
                                <li>{{ orderDetails.shippingDetails.state }}</li>
                                <li>{{ orderDetails.shippingDetails.country }}, {{ orderDetails.shippingDetails.postalcode }}</li>
                                <li>Contact No. {{ orderDetails.shippingDetails.phone }}</li>
                            </ul>
                        </div>-->

                        <div [hidden]="false" class="my-2 col-sm-12 payment-mode">
                            <h4>método de pago</h4>
                            <p>{{ orderDetails.waytopay }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Section ends -->
</ng-container>
<div hidden>
    <table id="table-invoice" cellpadding="0" cellspacing="0" class="esdev-mso-table" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;width:560px">
        <tr *ngFor="let item of orderDetails.items" style="border-top: 2px solid #e9e9e9;">
            <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                <table cellpadding="0" cellspacing="0" class="es-left" align="left" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                    <tr>
                        <td class="es-m-p0r" align="center" style="padding:0;Margin:0;width:265px">
                            <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tr>
                                    <td align="left" style="padding:0;Margin:0">
                                        <p
                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px">
                                            <b>{{item.name}}</b>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
            <td style="padding:0;Margin:0;width:10px"></td>
            <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                <table cellpadding="0" cellspacing="0" class="es-left" align="left" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                    <tr>
                        <td align="center" style="padding:0;Margin:0;width:70px">
                            <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tr>
                                    <td align="center" style="padding:0;Margin:0">
                                        <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px">
                                            <b>{{item.quantity}}</b>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
            <td style="padding:0;Margin:0;width:20px"></td>
            <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                <table cellpadding="0" cellspacing="0" class="es-left" align="left" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                    <tr>
                        <td align="left" style="padding:0;Margin:0;width:80px">
                            <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tr>
                                    <td align="center" style="padding:0;Margin:0">
                                        <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px">
                                            <b>$ {{this.ToFixed(item.price)}}</b>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
            <td style="padding:0;Margin:0;width:20px"></td>
            <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                <table cellpadding="0" cellspacing="0" class="es-right" align="right"
                       style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
                    <tr>
                        <td align="left" style="padding:0;Margin:0;width:85px">
                            <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tr>
                                    <td align="right" style="padding:0;Margin:0">
                                        <p
                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px">
                                            <b>$ {{this.ToFixed(item.price * item.quantity)}}</b>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</div>
