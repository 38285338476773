// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
        production: false,
        stripe_token: 'STRIPE_TOKEN',
        paypal_token: 'PAYPAL_TOKEN',
        // IANOS
        /*firebase: {
            apiKey: "AIzaSyDPw9J-pflR8khJ4PeFp-owHu-gUveYZLs",
            authDomain: "ianos-platform-dev.firebaseapp.com",
            databaseURL: "https://ianos-platform-dev-default-rtdb.firebaseio.com",
            projectId: "ianos-platform-dev",
            storageBucket: "ianos-platform-dev.appspot.com",
            messagingSenderId: "453907306516",
            appId: "1:453907306516:web:35d0a4df6afece46d82f01",
            measurementId: "G-1TY39ZT2WS"
        },
      */
        loading: {
            spinner: 'circles',
            duration: 3000
        },
        /*
           /!* googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
            urlBase: 'https://us-central1-ianos-platform-dev.cloudfunctions.net',
            urlBaseWompy: 'https://us-central1-ianos-platform-dev.cloudfunctions.net/Wompi',
            apikey: '6a4747e52f7-0428b4969d52d-3fb2-f509dd16',*!/

            // new rolling
            // googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
            // urlBase: 'https://us-central1-newrolling-pro.cloudfunctions.net',
            // urlBaseWompy: 'https://us-central1-newrolling-pro.cloudfunctions.net/Wompi',
            // urlBaseNR: 'https://api.newrolling.com',
            // apikey: '97ccc90e8fe-cce525a10ddf3-4cf7-415a29cd',

            // CREDENCIALES DE PRO
            // wompi: {
            //   grant_type: 'client_credentials', // Dejar siempre client_credentials.
            //   audience: 'wompi_api', // Dejar siempre "wompi_api"
            //   client_id: 'aa4c30e1-0757-4d03-a20f-b9cc2663bf5c', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
            //   client_secret: '259c97c1-06fe-4137-ab29-b331b92f0fcb', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
            // },


            // TIENDA CH
            /!*urlBase: 'https://us-central1-ch-tienda-online-app-dev.cloudfunctions.net',
            apikey: 'c159bde8e57-6c1149f12ee44-418c-eee56df2',
            urlBaseWompy: 'https://us-central1-ch-tienda-online-app-dev.cloudfunctions.net/Wompi',
            firebase: {
                apiKey: 'AIzaSyA6Ri30hxwSGkopJkPXDpirF2cyiorslaU',
                authDomain: 'ch-tienda-online-app-dev.firebaseapp.com',
                projectId: 'ch-tienda-online-app-dev',
                storageBucket: 'ch-tienda-online-app-dev.appspot.com',
                messagingSenderId: '543665834717',
                appId: '1:543665834717:web:d72a45b2950328f9236934',
                measurementId: 'G-E9NVECM858'
            },
            loadGif: 'assets/images/loader.gif',*!/
            // loadGif: 'https://firebasestorage.googleapis.com/v0/b/ch-tienda-online-app-dev.appspot.com/o/assets%2Fimages%2Fgif%2Fload_ch.gif?alt=media&token=86249d71-6b77-4113-b20b-d2cedfbde5f8',


            // NEW ROLLING DEV
            urlBase: 'https://us-central1-ianos-newrolling-dev.cloudfunctions.net',
            apikey: 'c68790b2244-50a832abf6f6e-4f4c-606dc078',
            urlBaseWompy: 'https://us-central1-ianos-platform-dev.cloudfunctions.net/Wompi',
            firebase: {
                apiKey: "AIzaSyACt8BEF8zwh49ErWHvj14ZdKwL-mqlLsE",
                authDomain: "ianos-newrolling-dev.firebaseapp.com",
                projectId: "ianos-newrolling-dev",
                storageBucket: "ianos-newrolling-dev.appspot.com",
                messagingSenderId: "1059308592105",
                appId: "1:1059308592105:web:0aba5eef8bf6f7cd100978",
                measurementId: "G-M90M91QVE7"
            },
            loadGif: 'assets/images/loader.gif',
            bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/newrolling-pro.appspot.com/o/assets%2Fimages%2Fbanner-default.jpg?alt=media&token=370f7870-1d05-4259-8a22-064e33789d08',
            // loadGif: 'https://firebasestorage.googleapis.com/v0/b/newrolling-pro.appspot.com/o/assets%2Fimages%2Fnew-rolling-gif-animated.gif?alt=media&token=7f2878cb-9302-4ac7-8a48-0469ee9ad92d',
            */

        // PARAMETROS NEW ROLLING PRO
        // firebase: {
        //     apiKey: "AIzaSyBgmFSjwQsnXaWREGx6wKIJ23c5_qhCeqI",
        //     authDomain: "newrolling-pro.firebaseapp.com",
        //     projectId: "newrolling-pro",
        //     storageBucket: "newrolling-pro.appspot.com",
        //     messagingSenderId: "883404852630",
        //     appId: "1:883404852630:web:c9496b6151d0689e0861b7",
        //     measurementId: "G-8E9QPRPXFE"
        // },
        // loadGif: 'assets/images/loader.gif',
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/newrolling-pro.appspot.com/o/assets%2Fimages%2Fbanner-default.jpg?alt=media&token=370f7870-1d05-4259-8a22-064e33789d08',
        // urlBase: 'https://us-central1-newrolling-pro.cloudfunctions.net',
        // urlBaseWompy: 'https://us-central1-newrolling-pro.cloudfunctions.net/Wompi',
        // urlBaseNR: 'https://api.newrolling.com',
        // apikey: '97ccc90e8fe-cce525a10ddf3-4cf7-415a29cd',

        // PARAMETROS NEW ROLLING DEV
        firebase: {
            apiKey: "AIzaSyACt8BEF8zwh49ErWHvj14ZdKwL-mqlLsE",
            authDomain: "ianos-newrolling-dev.firebaseapp.com",
            projectId: "ianos-newrolling-dev",
            storageBucket: "ianos-newrolling-dev.appspot.com",
            messagingSenderId: "1059308592105",
            appId: "1:1059308592105:web:0aba5eef8bf6f7cd100978",
            measurementId: "G-M90M91QVE7"
        },
        loadGif: 'assets/images/loader.gif',
        bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/newrolling-pro.appspot.com/o/assets%2Fimages%2Fbanner-default.jpg?alt=media&token=370f7870-1d05-4259-8a22-064e33789d08',

        urlBase: 'https://us-central1-ianos-newrolling-dev.cloudfunctions.net',
        urlBaseWompy: 'https://us-central1-ianos-newrolling-dev.cloudfunctions.net/Wompi',
        urlBaseNR: 'https://api.newrolling.com',
        apikey: 'c68790b2244-50a832abf6f6e-4f4c-606dc078',

        googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',

        // CREDENCIALES DE PRO New Rolling
        // wompi: {
        //     grant_type: 'client_credentials', // Dejar siempre client_credentials.
        //     audience: 'wompi_api', // Dejar siempre "wompi_api"
        //     client_id: 'aa4c30e1-0757-4d03-a20f-b9cc2663bf5c', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        //     client_secret: '259c97c1-06fe-4137-ab29-b331b92f0fcb', // Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        // },

        // CREDENCIALES DE PRO ITWC
        wompi: {
          grant_type: 'client_credentials', // Dejar siempre client_credentials.
          audience: 'wompi_api', // Dejar siempre "wompi_api"
          client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
          client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        },

        // CREDENCIALES DE TEST New Rolling
        // wompi: {
        //     grant_type: 'client_credentials', // Dejar siempre client_credentials.
        //     audience: 'wompi_api', // Dejar siempre "wompi_api"
        //     client_id: 'e0c71a50-c0f7-42c3-89e4-fee51345fb96', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        //     client_secret: 'a5a9d301-6ca7-407d-94f0-ea6b868ec857', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        // },


        countCharacter(string) {
            let limit = (this.width < 1560) ? 25 : 45;
            if (string.length > limit) string = string.substring(0, limit) + '...';
            return string;
        },

        breadcrumbDynamic(arrayBreadcrumb) {
            let sendBreadcrumbDynamic = [];
            arrayBreadcrumb.forEach(element => {
                if (element != '') {
                    let info = {
                        'name': element.replaceAll('-', ' '),
                        'url': element,
                    };
                    sendBreadcrumbDynamic.push(info);
                }
            });
            return sendBreadcrumbDynamic;
        }
    }
;


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

